export class PriceTable {
  constructor() {
    this.blocks = document.querySelectorAll('.js-price-table');
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.blocks) {
      const toggler = item.querySelector('.js-price-table-toggler');
      const content = item.querySelector('.js-price-table-content');
      this.closedSizeSetup(content);
      content.addEventListener('transitionend', () => {
        this.transitionEndHandler(content);
      });
      toggler.addEventListener('click', (e) => {
        this.toggleTable(e, content);
      });
    }
  };
  closedSizeSetup = (content) => {
    const rowsEl = content.querySelectorAll('tr');
    let height = 0;
    for (let index = 0; index < 7; index++) {
      const element = rowsEl[index];
      height += element.scrollHeight;
    }
    content.setAttribute('data-height', height);
    content.setAttribute('data-show', 'close');
    content.style.cssText = `height:${height}px`;
  };
  toggleTable = (e, content) => {
    const attrFlag = content.getAttribute('data-show');
    const toggler = e.currentTarget;
    if (attrFlag === 'close') {
      content.style.cssText = `height:${content.scrollHeight}px`;
      content.setAttribute('data-show', 'open');
      toggler.classList.add('active');
    } else {
      content.style.cssText = `height:${content.scrollHeight}px`;
      setTimeout(() => {
        content.style.cssText = `height:${content.getAttribute('data-height')}px`;
      }, 0);
      content.setAttribute('data-show', 'close');
      toggler.classList.remove('active');
    }
  };
  transitionEndHandler = (content) => {
    const attrFlag = content.getAttribute('data-show');
    if (attrFlag === 'open') {
      content.style.cssText = `height:auto`;
    }
  };
}
