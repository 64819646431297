import { CreateMobileSlider } from '@/js/helpers';
import { Pagination } from 'swiper';
export class SmartMobileSlider {
  constructor() {
    this.blocks = document.querySelectorAll('.js-smart-mobile-slider');
    this.config = {
      '3_in_row': {
        320: {
          slidesPerView: 1,
        },
        450: {
          slidesPerView: 1.5,
        },
        600: {
          slidesPerView: 2,
        },
      },
      fr_cards: {
        320: {
          slidesPerView: 1,
        },
        400: {
          slidesPerView: 1.5,
        },
        600: {
          slidesPerView: 2.3,
        },
        900: {
          slidesPerView: 3,
        },
      },
      '4_in_row': {
        320: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 2,
        },
        1100: {
          slidesPerView: 3,
        },
      },
      showcase_row: {
        320: {
          slidesPerView: 1,
        },
        575: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 3,
        },
      },
      optional: {
        320: {
          slidesPerView: 1,
        },
        390: {
          slidesPerView: 2,
        },
        700: {
          slidesPerView: 3,
        },
        1000: {
          slidesPerView: 4,
        },
      },
      '5_in_row': {
        320: {
          slidesPerView: 2,
        },
        500: {
          slidesPerView: 3,
        },
        800: {
          slidesPerView: 4,
        },
        1000: {
          slidesPerView: 5,
        },
      },
      '5_in_row_new': {
        320: {
          slidesPerView: 1,
        },
        576: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1100: {
          slidesPerView: 4,
        },
        1300: {
          slidesPerView: 5,
        },
      },
    };
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.blocks) {
      const cardsInRow = item.getAttribute('data-row') || '3_in_row';
      new CreateMobileSlider({
        containerCssSelector: item,
        sliderOptions: {
          modules: [Pagination],
          pagination: {
            el: '.swiper-pagination',
          },
          spaceBetween: 20,
          breakpoints: this.config[cardsInRow],
        },
      });
    }
  };
}
