import Swiper, { Navigation, Pagination } from 'swiper';

export class PdCharsSlider {
  constructor() {
    this.blocks = document.querySelectorAll('.js-pd-chars-slider');
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.blocks) {
      const sliderEl = item.querySelector('.swiper');
      const prevEl = item.querySelector('.pd-chars__control_prev');
      const nextEl = item.querySelector('.pd-chars__control_next');
      const pagination = item.querySelector('.swiper-pagination');
      new Swiper(sliderEl, {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        navigation: {
          prevEl: prevEl,
          nextEl: nextEl,
          disabledClass: 'disabled',
        },
        pagination: {
          el: pagination,
        },
      });
    }
  };
}