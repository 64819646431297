import { onlyNumbers } from '@/js/helpers';

export class GlCalc {
  constructor() {
    this.options = {
      typeStandart: {
        'two-section': {
          standart: 'gl-two-standart.png',
          'g-type': 'gl-two-g.png',
          'p-type': 'gl-two-p.png',
          'radius-type': 'gl-two-radius.png',
        },
        'three-section': {
          standart: 'gl-three-standart.png',
          'g-type': 'gl-three-g.png',
          'p-type': 'gl-three-p.png',
          'radius-type': 'gl-three-radius.png',
        },
      },
      typePanoram: {
        'two-section': {
          standart: 'gl-two-standart.png',
          'g-type': 'gl-two-g.png',
          'p-type': 'gl-two-p.png',
          'radius-type': 'gl-two-radius.png',
          'zig-zag-type': 'gl-two-zig-zag.png',
          'stvorka-type': 'gl-two-stvorka.png',
          'form-type': 'gl-two-form.png',
        },
        'three-section': {
          standart: 'gl-three-standart.png',
          'g-type': 'gl-three-g.png',
          'p-type': 'gl-three-p.png',
          'radius-type': 'gl-three-radius.png',
          'zig-zag-type': 'gl-three-zig-zag.png',
          'stvorka-type': 'gl-three-stvorka.png',
          'form-type': 'gl-three-form.png',
        },
      },
    };
    this.block = document.getElementById('gl-calc');
    this.pathToImg = '';
    this.viewEl = null;
    this.typeRadioEl = null;
    this.kindRadioEl = null;
    this.widthEl = null;
    this.heightEl = null;
    this.orderEl = null;

    this.data = null;
    this.state = {
      section: null,
      kind: null,
    };
    this.formData = null;
    this.block && this.init();
  }
  init = () => {
    try {
      this.pathToImg = this.block.getAttribute('data-path');
      this.data = this.options[this.block.getAttribute('data-type')];
      this.formEl = this.block.querySelector('.js-gl-calc-form');
      this.viewEl = this.block.querySelector('.js-calc-view');
      this.typeRadioEl = this.block.querySelectorAll('.js-calc-type');
      this.kindRadioEl = this.block.querySelectorAll('.js-calc-kind');
      this.widthEl = this.block.querySelector('.js-calc-width');
      this.heightEl = this.block.querySelector('.js-calc-height');
      this.orderEl = this.block.querySelector('.js-calc-order');

      this.onlyNumbersEl = this.block.querySelectorAll('.js-only-numbers');

      this.setupInitialState();
      this.setupListener();
    } catch (error) {}
  };
  setupInitialState = () => {
    this.block.querySelector('[data-type=two-section]').setAttribute('checked', '');
    this.state['section'] = 'two-section';
    this.block.querySelector('[data-type=standart]').setAttribute('checked', '');
    this.state['kind'] = 'standart';
    this.changeView();
  };
  setupListener = () => {
    for (const item of this.typeRadioEl) {
      item.addEventListener('change', (e) => {
        this.state['section'] = e.target.getAttribute('data-type');
        this.changeView();
      });
    }
    for (const item of this.kindRadioEl) {
      item.addEventListener('change', (e) => {
        this.state['kind'] = e.target.getAttribute('data-type');
        this.changeView();
      });
    }
    this.orderEl.addEventListener('click', () => {
      let outPutStr = '';
      const formData = new FormData(this.formEl);
      formData.forEach((value, key) => {
        switch (key) {
          case 'width':
            outPutStr += `Ширина: ${value} мм;\n`;
            break;
          case 'height':
            outPutStr += `Высота: ${value} мм;\n`;
            break;
          case 'rotatable_count':
            outPutStr += `Количество поворотных створок: ${value};\n`;
            break;
          case 'rotary_folding_count':
            outPutStr += `Количество поворотно-откидных створок: ${value};\n`;
            break;
          case 'options':
            outPutStr += `Дополнительная опция: ${value};\n`;
            break;
          default:
            outPutStr += `${value};\n`;
            break;
        }
      });
      this.block.querySelector('.js-calc-output').value = outPutStr;
    });
    for (const item of this.onlyNumbersEl) {
      ['keyup', 'input'].forEach((event) => {
        item.addEventListener(event, (e) => {
          e.target.value = onlyNumbers(e.target.value);
        });
      });
    }
  };
  setupOutPutChange = () => {
    ['change', 'input', 'keyup'].forEach((event) => {});
  };
  changeView = () => {
    if (this.state['section'] && this.state['kind']) {
      const path = `${this.pathToImg}/${this.data[this.state['section']][this.state['kind']]}`;
      this.viewEl.setAttribute('src', path);
    }
  };
}
