export class Header {
  constructor() {
    this.headerEl = document.querySelector('.header');
    this.phoneDropTrigger = document.querySelector('.js-phone-dropdown-trigger');
    this.searchDropTrigger = document.querySelector('.js-search-dropdown-trigger');
    this.dropDownPanel = document.querySelector('.js-phone-dropdown');
    this.searchDropDownPanel = document.querySelector('.js-search-dropdown');
    this.phoneDropDownClose = document.querySelector('.js-phone-dropdown-close');
    this.searchDropDownClose = document.querySelector('.js-search-dropdown-close');
    if (this.headerEl) {
      this.init();
    }
  }
  init = () => {
    this.phoneDropTrigger.addEventListener('click', () => {
      this.dropDownPanel.classList.add('active');
      this.searchDropDownPanel.classList.remove('active');
    });
    this.phoneDropDownClose.addEventListener('click', () => {
      this.dropDownPanel.classList.remove('active');
    });
    this.searchDropTrigger.addEventListener('click', () => {
      this.searchDropDownPanel.classList.add('active');
      this.dropDownPanel.classList.remove('active');
    });
    this.searchDropDownClose.addEventListener('click', () => {
      this.searchDropDownPanel.classList.remove('active');
    });
  };
}
