import React from 'react';
import ReactDOM from 'react-dom/client';
import { CalcPrice } from './CalcPrice';

export default function initCalcPrice() {
  if (document.getElementById('calc-price')) {
    const root = ReactDOM.createRoot(document.getElementById('calc-price'));
    root.render(
      <React.StrictMode>
        <CalcPrice />
      </React.StrictMode>
    );
  }
}
