import Swiper, { Navigation } from 'swiper';

export class HandleCalc {
  constructor() {
    this.block = document.querySelector('.handle-calc');
    this.state = {
      laminationView: null,
      laminationBtn: [],
      handleView: null,
      handleBtn: [],
      activeLamination: null,
      activeHandle: null,
    };
    this.block && this.init();
  }
  init = () => {
    this.state.laminationBtn = this.block.querySelectorAll('.js-lamination-switch');
    this.state.laminationView = this.block.querySelector('.handle-calc__lamination');
    this.state.handleBtn = this.block.querySelectorAll('.js-handle-switch');
    this.state.handleView = this.block.querySelector('.handle-calc__handle');
    const groupEl = this.block.querySelectorAll('.handle-calc__group');
    for (const item of groupEl) {
      this.initControlSlider(item);
    }
    this.initBtn();
    this.setupInitialImages();
  };
  setupInitialImages = () => {
    this.state.activeLamination = this.state.laminationBtn[0];
    this.state.activeHandle = this.state.handleBtn[0];
    this.state.laminationView.setAttribute(
      'src',
      this.state.activeLamination.getAttribute('data-img')
    );
    this.state.handleView.setAttribute('src', this.state.activeHandle.getAttribute('data-img'));
  };
  initControlSlider = (box) => {
    const sliderEl = box.querySelector('.swiper');
    const prevEl = box.querySelector('.handle-calc__arrow_prev');
    const nextEl = box.querySelector('.handle-calc__arrow_next');
    new Swiper(sliderEl, {
      spaceBetween: 11,
      navigation: {
        prevEl,
        nextEl,
        disabledClass: 'disabled',
      },
      modules: [Navigation],
      breakpoints: {
        0: {
          slidesPerView: 2,
        },
        370: {
          slidesPerView: 3,
        },
        500: {
          slidesPerView: 4,
        },
        701: {
          slidesPerView: 3,
        },
        800: {
          slidesPerView: 4,
        },
        993: {
          slidesPerView: 5,
        },
      },
    });
  };
  initBtn = () => {
    for (const item of this.state.handleBtn) {
      item.addEventListener('click', () => {
        this.state.handleView.setAttribute('src', item.getAttribute('data-img'));
        this.state.activeHandle.classList.remove('active');
        item.classList.add('active');
        this.state.activeHandle = item;
      });
    }
    for (const item of this.state.laminationBtn) {
      item.addEventListener('click', () => {
        this.state.laminationView.setAttribute('src', item.getAttribute('data-img'));
        this.state.activeLamination.classList.remove('active');
        item.classList.add('active');
        this.state.activeLamination = item;
      });
    }
  };
}
