import Swiper, { Navigation, Pagination } from 'swiper';

export function prodSlider() {
  try {
    const blocks = document.querySelectorAll('.js-prod-slider');
    for (const item of blocks) {
      const prevEl = item.querySelector('.prod-slider__arrow_prev');
      const nextEl = item.querySelector('.prod-slider__arrow_next');
      const paginationEl = item.querySelector('.swiper-pagination');
      new Swiper(item.querySelector('.swiper'), {
        modules: [Navigation, Pagination],

        spaceBetween: 30,
        navigation: {
          prevEl,
          nextEl,
          disabledClass: 'disabled',
          lockClass: 'lock',
        },
        pagination: {
          el: paginationEl,
        },
        breakpoints: {
          850: {
            slidesPerView: 2,
          },
        },
      });
    }
  } catch (error) {
    //
  }
}
