export const ralData = [
  {
    key: 'ral1000',
    name: 'RAL 1000',
    color: '#CABE8E',
  },
  {
    key: 'ral1001',
    name: 'RAL 1001',
    color: '#CEB58C',
  },
  {
    key: 'ral1002',
    name: 'RAL 1002',
    color: '#CFAF76',
  },
  {
    key: 'ral1003',
    name: 'RAL 1003',
    color: '#F2AD22',
  },
  {
    key: 'ral1004',
    name: 'RAL 1004',
    color: '#DFA31F',
  },
  {
    key: 'ral1005',
    name: 'RAL 1005',
    color: '#C89625',
  },
  {
    key: 'ral1006',
    name: 'RAL 1006',
    color: '#DD9622',
  },
  {
    key: 'ral1007',
    name: 'RAL 1007',
    color: '#E5941F',
  },
  {
    key: 'ral1011',
    name: 'RAL 1011',
    color: '#AF885D',
  },
  {
    key: 'ral1012',
    name: 'RAL 1012',
    color: '#D8B33E',
  },
  {
    key: 'ral1013',
    name: 'RAL 1013',
    color: '#E4DDCB',
  },
  {
    key: 'ral1014',
    name: 'RAL 1014',
    color: '#DCC6A1',
  },
  {
    key: 'ral1015',
    name: 'RAL 1015',
    color: '#E5D5BB',
  },
  {
    key: 'ral1016',
    name: 'RAL 1016',
    color: '#EDE334',
  },
  {
    key: 'ral1017',
    name: 'RAL 1017',
    color: '#F2AE5D',
  },
  {
    key: 'ral1018',
    name: 'RAL 1018',
    color: '#F4D046',
  },
  {
    key: 'ral1019',
    name: 'RAL 1019',
    color: '#A69480',
  },
  {
    key: 'ral1020',
    name: 'RAL 1020',
    color: '#A2946D',
  },
  {
    key: 'ral1021',
    name: 'RAL 1021',
    color: '#EEBB00',
  },

  {
    key: 'ral1023',
    name: 'RAL 1023',
    color: '#F1BB01',
  },
  {
    key: 'ral1024',
    name: 'RAL 1024',
    color: '#B99559',
  },
  {
    key: 'ral1026',
    name: 'RAL 1026',
    color: '#F0FD00',
  },
  {
    key: 'ral1027',
    name: 'RAL 1027',
    color: '#A78734',
  },
  {
    key: 'ral1028',
    name: 'RAL 1028',
    color: '#FFA200',
  },
  {
    key: 'ral1032',
    name: 'RAL 1032',
    color: '#DBA829',
  },
  {
    key: 'ral1033',
    name: 'RAL 1033',
    color: '#F3A038',
  },
  {
    key: 'ral1034',
    name: 'RAL 1034',
    color: '#E9A35E',
  },
  {
    key: 'ral1035',
    name: 'RAL 1035',
    color: '#887E6B',
  },
  {
    key: 'ral1036',
    name: 'RAL 1036',
    color: '#856E44',
  },
  {
    key: 'ral1037',
    name: 'RAL 1037',
    color: '#EB982E',
  },
  {
    key: 'ral2000',
    name: 'RAL 2000',
    color: '#D7772D',
  },
  {
    key: 'ral2001',
    name: 'RAL 2001',
    color: '#BA563C',
  },
  {
    key: 'ral2002',
    name: 'RAL 2002',
    color: '#BE483C',
  },
  {
    key: 'ral2003',
    name: 'RAL 2003',
    color: '#F28044',
  },
  {
    key: 'ral2004',
    name: 'RAL 2004',
    color: '#DF5E34',
  },
  {
    key: 'ral2005',
    name: 'RAL 2005',
    color: '#FF1502',
  },
  {
    key: 'ral2007',
    name: 'RAL 2007',
    color: '#FF9600',
  },
  {
    key: 'ral2008',
    name: 'RAL 2008',
    color: '#E9723A',
  },
  {
    key: 'ral2009',
    name: 'RAL 2009',
    color: '#DB5E32',
  },
  {
    key: 'ral2010',
    name: 'RAL 2010',
    color: '#CC653C',
  },
  {
    key: 'ral2011',
    name: 'RAL 2011',
    color: '#E27638',
  },
  {
    key: 'ral2012',
    name: 'RAL 2012',
    color: '#D46B58',
  },
  {
    key: 'ral2013',
    name: 'RAL 2013',
    color: '#934424',
  },
  {
    key: 'ral3000',
    name: 'RAL 3000',
    color: '#A8403D',
  },
  {
    key: 'ral3001',
    name: 'RAL 3001',
    color: '#A03C3E',
  },
  {
    key: 'ral3002',
    name: 'RAL 3002',
    color: '#9F3B3D',
  },
  {
    key: 'ral3003',
    name: 'RAL 3003',
    color: '#8A383E',
  },
  {
    key: 'ral3004',
    name: 'RAL 3004',
    color: '#753C42',
  },
  {
    key: 'ral3005',
    name: 'RAL 3005',
    color: '#663D43',
  },
  {
    key: 'ral3007',
    name: 'RAL 3007',
    color: '#513E40',
  },
  {
    key: 'ral3009',
    name: 'RAL 3009',
    color: '#734641',
  },
  {
    key: 'ral3011',
    name: 'RAL 3011',
    color: '#7D3D3D',
  },
  {
    key: 'ral3012',
    name: 'RAL 3012',
    color: '#C88B78',
  },
  {
    key: 'ral3013',
    name: 'RAL 3013',
    color: '#99443F',
  },
  {
    key: 'ral3014',
    name: 'RAL 3014',
    color: '#CB797D',
  },
  {
    key: 'ral3015',
    name: 'RAL 3015',
    color: '#D8A5AE',
  },
  {
    key: 'ral3016',
    name: 'RAL 3016',
    color: '#A94C44',
  },
  {
    key: 'ral3017',
    name: 'RAL 3017',
    color: '#CA5F69',
  },
  {
    key: 'ral3018',
    name: 'RAL 3018',
    color: '#C74F59',
  },
  {
    key: 'ral3020',
    name: 'RAL 3020',
    color: '#BB3939',
  },
  {
    key: 'ral3022',
    name: 'RAL 3022',
    color: '#CD705E',
  },
  {
    key: 'ral3024',
    name: 'RAL 3024',
    color: '#F60100',
  },
  {
    key: 'ral3026',
    name: 'RAL 3026',
    color: '#FE0000',
  },
  {
    key: 'ral3027',
    name: 'RAL 3027',
    color: '#AD3E4F',
  },
  {
    key: 'ral3028',
    name: 'RAL 3028',
    color: '#CB3234',
  },
  {
    key: 'ral3031',
    name: 'RAL 3031',
    color: '#AB464E',
  },
  {
    key: 'ral3032',
    name: 'RAL 3032',
    color: '#751521',
  },
  {
    key: 'ral3033',
    name: 'RAL 3033',
    color: '#B54740',
  },
  {
    key: 'ral4001',
    name: 'RAL 4001',
    color: '#8C6A8B',
  },
  {
    key: 'ral4002',
    name: 'RAL 4002',
    color: '#924E5D',
  },
  {
    key: 'ral4003',
    name: 'RAL 4003',
    color: '#C66A93',
  },
  {
    key: 'ral4004',
    name: 'RAL 4004',
    color: '#703B4D',
  },
  {
    key: 'ral4005',
    name: 'RAL 4005',
    color: '#8470A1',
  },
  {
    key: 'ral4006',
    name: 'RAL 4006',
    color: '#97467E',
  },
  {
    key: 'ral4007',
    name: 'RAL 4007',
    color: '#573E51',
  },
  {
    key: 'ral4008',
    name: 'RAL 4008',
    color: '#8D5789',
  },
  {
    key: 'ral4009',
    name: 'RAL 4009',
    color: '#A38B98',
  },
  {
    key: 'ral4010',
    name: 'RAL 4010',
    color: '#C05082',
  },
  {
    key: 'ral4011',
    name: 'RAL 4011',
    color: '#836593',
  },
  {
    key: 'ral4012',
    name: 'RAL 4012',
    color: '#716880',
  },
  {
    key: 'ral5000',
    name: 'RAL 5000',
    color: '#485B79',
  },
  {
    key: 'ral5001',
    name: 'RAL 5001',
    color: '#38586F',
  },
  {
    key: 'ral5002',
    name: 'RAL 5002',
    color: '#3F4B87',
  },
  {
    key: 'ral5003',
    name: 'RAL 5003',
    color: '#3B4963',
  },
  {
    key: 'ral5004',
    name: 'RAL 5004',
    color: '#3C3F48',
  },
  {
    key: 'ral5005',
    name: 'RAL 5005',
    color: '#2B5D8E',
  },
  {
    key: 'ral5007',
    name: 'RAL 5007',
    color: '#4F7194',
  },
  {
    key: 'ral5008',
    name: 'RAL 5008',
    color: '#454C56',
  },
  {
    key: 'ral5009',
    name: 'RAL 5009',
    color: '#3E667F',
  },
  {
    key: 'ral5010',
    name: 'RAL 5010',
    color: '#2B5A84',
  },
  {
    key: 'ral5011',
    name: 'RAL 5011',
    color: '#3C4250',
  },
  {
    key: 'ral5012',
    name: 'RAL 5012',
    color: ' #418EBA',
  },
  {
    key: 'ral5013',
    name: 'RAL 5013',
    color: '#3C4661',
  },
  {
    key: 'ral5014',
    name: 'RAL 5014',
    color: '#71839B',
  },
  {
    key: 'ral5015',
    name: 'RAL 5015',
    color: '#3082B4',
  },
  {
    key: 'ral5017',
    name: 'RAL 5017',
    color: '#186694',
  },
  {
    key: 'ral5018',
    name: 'RAL 5018',
    color: '#389293',
  },
  {
    key: 'ral5019',
    name: 'RAL 5019',
    color: '#31678B',
  },
  {
    key: 'ral5020',
    name: 'RAL 5020',
    color: '#2A525A',
  },
  {
    key: 'ral5021',
    name: 'RAL 5021',
    color: '#097D7D',
  },
  {
    key: 'ral5022',
    name: 'RAL 5022',
    color: '#444267',
  },
  {
    key: 'ral5023',
    name: 'RAL 5023',
    color: '#5A7495',
  },
  {
    key: 'ral5024',
    name: 'RAL 5024',
    color: '#7098B1',
  },
  {
    key: 'ral5025',
    name: 'RAL 5025',
    color: '#336275',
  },
  {
    key: 'ral5026',
    name: 'RAL 5026',
    color: '#212A51',
  },
  {
    key: 'ral6000',
    name: 'RAL 6000',
    color: '#4E7D6B',
  },
  {
    key: 'ral6001',
    name: 'RAL 6001',
    color: '#437248',
  },
  {
    key: 'ral6002',
    name: 'RAL 6002',
    color: '#456540',
  },
  {
    key: 'ral6003',
    name: 'RAL 6003',
    color: '#5A5E4D',
  },
  {
    key: 'ral6004',
    name: 'RAL 6004',
    color: '#335554',
  },
  {
    key: 'ral6005',
    name: 'RAL 6005',
    color: '#365148',
  },
  {
    key: 'ral6006',
    name: 'RAL 6006',
    color: '#4E4E46',
  },
  {
    key: 'ral6007',
    name: 'RAL 6007',
    color: '#43493F',
  },
  {
    key: 'ral6008',
    name: 'RAL 6008',
    color: '#47473F',
  },
  {
    key: 'ral6009',
    name: 'RAL 6009',
    color: '#3F4941',
  },
  {
    key: 'ral6010',
    name: 'RAL 6010',
    color: '#547546',
  },
  {
    key: 'ral6011',
    name: 'RAL 6011',
    color: '#728567',
  },
  {
    key: 'ral6012',
    name: 'RAL 6012',
    color: '#444D4C',
  },
  {
    key: 'ral6013',
    name: 'RAL 6013',
    color: '#827C64',
  },
  {
    key: 'ral6014',
    name: 'RAL 6014',
    color: '#534E48',
  },
  {
    key: 'ral6015',
    name: 'RAL 6015',
    color: '#4A4D46',
  },
  {
    key: 'ral6016',
    name: 'RAL 6016',
    color: '#167259',
  },
  {
    key: 'ral6017',
    name: 'RAL 6017',
    color: '#5E874D',
  },
  {
    key: 'ral6018',
    name: 'RAL 6018',
    color: '#619F49',
  },
  {
    key: 'ral6019',
    name: 'RAL 6019',
    color: '#B9D1AF',
  },
  {
    key: 'ral6020',
    name: 'RAL 6020',
    color: '#4A5449',
  },
  {
    key: 'ral6021',
    name: 'RAL 6021',
    color: '#8DA080',
  },
  {
    key: 'ral6022',
    name: 'RAL 6022',
    color: '#4B4640',
  },
  {
    key: 'ral6024',
    name: 'RAL 6024',
    color: '#298C5E',
  },
  {
    key: 'ral6025',
    name: 'RAL 6025',
    color: '#64774A',
  },
  {
    key: 'ral6026',
    name: 'RAL 6026',
    color: '#07695A',
  },
  {
    key: 'ral6027',
    name: 'RAL 6027',
    color: '#85BEB8',
  },
  {
    key: 'ral6028',
    name: 'RAL 6028',
    color: '#446052',
  },
  {
    key: 'ral6029',
    name: 'RAL 6029',
    color: '#00784B',
  },
  {
    key: 'ral6032',
    name: 'RAL 6032',
    color: '#36875E',
  },
  {
    key: 'ral6033',
    name: 'RAL 6033',
    color: '#578F86',
  },
  {
    key: 'ral6034',
    name: 'RAL 6034',
    color: '#85B1B0',
  },
  {
    key: 'ral6035',
    name: 'RAL 6035',
    color: '#044D26',
  },
  {
    key: 'ral6036',
    name: 'RAL 6036',
    color: '#015D4F',
  },
  {
    key: 'ral6037',
    name: 'RAL 6037',
    color: '#008E38',
  },
  {
    key: 'ral6038',
    name: 'RAL 6038',
    color: '#00BB2E',
  },
  {
    key: 'ral7000',
    name: 'RAL 7000',
    color: '#838E94',
  },
  {
    key: 'ral7001',
    name: 'RAL 7001',
    color: '#949BA3',
  },
  {
    key: 'ral7002',
    name: 'RAL 7002',
    color: '#898270',
  },
  {
    key: 'ral7003',
    name: 'RAL 7003',
    color: '#7D7D71',
  },
  {
    key: 'ral7004',
    name: 'RAL 7004',
    color: '#A0A0A0',
  },
  {
    key: 'ral7005',
    name: 'RAL 7005',
    color: '#767875',
  },
  {
    key: 'ral7006',
    name: 'RAL 7006',
    color: '#7A7267',
  },
  {
    key: 'ral7008',
    name: 'RAL 7008',
    color: '#7A6A50',
  },
  {
    key: 'ral7009',
    name: 'RAL 7009',
    color: '#646760',
  },
  {
    key: 'ral7010',
    name: 'RAL 7010',
    color: '#616660',
  },
  {
    key: 'ral7011',
    name: 'RAL 7011',
    color: '#5E6367',
  },
  {
    key: 'ral7012',
    name: 'RAL 7012',
    color: '#656A6D',
  },
  {
    key: 'ral7013',
    name: 'RAL 7013',
    color: '#605C51',
  },
  {
    key: 'ral7015',
    name: 'RAL 7015',
    color: '#5D6065',
  },
  {
    key: 'ral7016',
    name: 'RAL 7016',
    color: '#4C4F54',
  },
  {
    key: 'ral7021',
    name: 'RAL 7021',
    color: '#47484A',
  },
  {
    key: 'ral7022',
    name: 'RAL 7022',
    color: '#5A5955',
  },
  {
    key: 'ral7023',
    name: 'RAL 7023',
    color: '#85867E',
  },
  {
    key: 'ral7024',
    name: 'RAL 7024',
    color: '#56575C',
  },
  {
    key: 'ral7026',
    name: 'RAL 7026',
    color: '#4B5355',
  },
  {
    key: 'ral7030',
    name: 'RAL 7030',
    color: '#97948D',
  },
  {
    key: 'ral7031',
    name: 'RAL 7031',
    color: '#6B727A',
  },
  {
    key: 'ral7032',
    name: 'RAL 7032',
    color: '#B7B4A5 ',
  },
  {
    key: 'ral7033',
    name: 'RAL 7033',
    color: '#868A7C',
  },
  {
    key: 'ral7034',
    name: 'RAL 7034',
    color: '#96907A',
  },
  {
    key: 'ral7035',
    name: 'RAL 7035',
    color: '#C9CBC8',
  },
  {
    key: 'ral7036',
    name: 'RAL 7036',
    color: '#9D9998',
  },
  {
    key: 'ral7037',
    name: 'RAL 7037',
    color: '#858585',
  },
  {
    key: 'ral7038',
    name: 'RAL 7038',
    color: '#B2B5AE',
  },
  {
    key: 'ral7039',
    name: 'RAL 7039',
    color: '#74716A',
  },
  {
    key: 'ral7040',
    name: 'RAL 7040',
    color: '#9EA3A7',
  },
  {
    key: 'ral7042',
    name: 'RAL 7042',
    color: '#949899',
  },
  {
    key: 'ral7043',
    name: 'RAL 7043',
    color: '#5D5F5E',
  },
  {
    key: 'ral7044',
    name: 'RAL 7044',
    color: '#BBB6B0',
  },
  {
    key: 'ral7045',
    name: 'RAL 7045',
    color: '#94989B',
  },
  {
    key: 'ral7046',
    name: 'RAL 7046',
    color: '#878C90',
  },
  {
    key: 'ral7047',
    name: 'RAL 7047',
    color: '#CACACA',
  },
  {
    key: 'ral7048',
    name: 'RAL 7048',
    color: '#858071',
  },
  {
    key: 'ral8000',
    name: 'RAL 8000',
    color: '#8B734F',
  },
  {
    key: 'ral8001',
    name: 'RAL 8001',
    color: '#9E6D44',
  },
  {
    key: 'ral8002',
    name: 'RAL 8002',
    color: '#7F5B4F',
  },
  {
    key: 'ral8003',
    name: 'RAL 8003',
    color: '#845A41',
  },
  {
    key: 'ral8004',
    name: 'RAL 8004',
    color: '#915847',
  },
  {
    key: 'ral8007',
    name: 'RAL 8007',
    color: '#775441',
  },
  {
    key: 'ral8008',
    name: 'RAL 8008',
    color: '#795B43',
  },
  {
    key: 'ral8011',
    name: 'RAL 8011',
    color: '#654A3F',
  },
  {
    key: 'ral8012',
    name: 'RAL 8012',
    color: '#6E4742',
  },
  {
    key: 'ral8014',
    name: 'RAL 8014',
    color: '#56483F',
  },
  {
    key: 'ral8015',
    name: 'RAL 8015',
    color: '#694642',
  },
  {
    key: 'ral8016',
    name: 'RAL 8016',
    color: '#5B443E',
  },
  {
    key: 'ral8017',
    name: 'RAL 8017',
    color: '#534542',
  },
  {
    key: 'ral8019',
    name: 'RAL 8019',
    color: '#4E4848',
  },
  {
    key: 'ral8022',
    name: 'RAL 8022',
    color: '#3D3C3A',
  },
  {
    key: 'ral8023',
    name: 'RAL 8023',
    color: '#A66442',
  },
  {
    key: 'ral8024',
    name: 'RAL 8024',
    color: '#7E5E4F',
  },
  {
    key: 'ral8025',
    name: 'RAL 8025',
    color: '#7C6255',
  },
  {
    key: 'ral8028',
    name: 'RAL 8028',
    color: '#5E4D43',
  },
  {
    key: 'ral8029',
    name: 'RAL 8029',
    color: '#80432A',
  },
  {
    key: 'ral9001',
    name: 'RAL 9001',
    color: '#EAE2D7',
  },
  {
    key: 'ral9002',
    name: 'RAL 9002',
    color: '#D8D8D0',
  },
  {
    key: 'ral9003',
    name: 'RAL 9003',
    color: '#EEEEEC',
  },
  {
    key: 'ral9004',
    name: 'RAL 9004',
    color: '#444446',
  },
  {
    key: 'ral9005',
    name: 'RAL 9005',
    color: '#3C3C3C',
  },
  {
    key: 'ral9006',
    name: 'RAL 9006',
    color: '#A6A5A2',
  },
  {
    key: 'ral9007',
    name: 'RAL 9007',
    color: '#8F8E8A',
  },
  {
    key: 'ral9010',
    name: 'RAL 9010',
    color: '#F2EFE8',
  },
  {
    key: 'ral9011',
    name: 'RAL 9011',
    color: '#3E3F41',
  },
  {
    key: 'ral9016',
    name: 'RAL 9016',
    color: '#F1F2ED',
  },
  {
    key: 'ral9017',
    name: 'RAL 9017',
    color: '#404040',
  },
  {
    key: 'ral9018',
    name: 'RAL 9018',
    color: '#CACFC9',
  },
  {
    key: 'ral9022',
    name: 'RAL 9022',
    color: '#A0A1A0',
  },
  {
    key: 'ral9023',
    name: 'RAL 9023',
    color: '#909293',
  },
];
