import Swiper, { Pagination } from 'swiper';

export class cenySlider {
  constructor() {
    this.blocks = document.querySelectorAll('.js-slider-ceny');
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.blocks) {
      const sliderEl = item.querySelector('.swiper');
      const slider = new Swiper(sliderEl, {
        modules: [Pagination],
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 4,
          },
        },
        // on: {
        //   breakpoint: (swiper, breakpoint) => {
        //     if (swiper.slides.length < breakpoint.slidesPerView) {
        //       swiper.wrapperEl.classList.add('swiper-wrapper-center');
        //     } else {
        //       swiper.wrapperEl.classList.remove('swiper-wrapper-center');
        //     }
        //   },
        // },
      });
    }
  };
}
