import JustValidate from 'just-validate';
import Inputmask from 'inputmask';
export class FormValidation {
  constructor() {
    this.telReg = new RegExp(
      /^(\+7|7|8)?[\s\-]?\(?[0-9][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/,
      'g'
    );
    // this.init();
  }
  initAll = () => {
    const formSimple = document.querySelectorAll('.js-simple-form');

    if (formSimple.length > 0) {
      for (const item of formSimple) {
        const attr = item.getAttribute('novalidate');
        !attr && this.simpleCallBack(item);
      }
    }
  };
  simpleCallBack = (form, successSubmit) => {
    //input mask
    const im = new Inputmask('+7(999)-999-99-99');
    im.mask(form.querySelectorAll('.js-tel-mask'));
    const validator = new JustValidate(form, {
      // successFieldCssClass: 'round-input__input_valid',
      errorFieldCssClass: 'error',
      errorLabelCssClass: 'error-label',
      errorLabelStyle: '',
    });
    validator.onSuccess(() => {
      form.submit();
      successSubmit && successSubmit();
    });
    const phoneField = form.querySelector('.js-phone-validation');
    validator.addField(phoneField, [
      {
        rule: 'required',
        errorMessage: 'Поле обязтельно для заполнения',
      },
      {
        rule: 'customRegexp',
        value: this.telReg,
        errorMessage: 'Введите телефон в формате +7(999)-999-99-99',
      },
    ]);
  };
}
