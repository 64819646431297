export class FInTypes {
  constructor() {
    this.items = document.querySelectorAll('.js-modal-helper');
    this.items.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.items) {
      const openModalBtn = item.querySelector('.js-modal-helper-open');
      const modalEl = item.querySelector('.js-modal-helper-modal');
      const closeEl = item.querySelector('.js-modal-helper-close');
      openModalBtn.addEventListener('click', () => {
        this.openModal(modalEl);
      });
      closeEl.addEventListener('click', () => {
        this.closeModal(modalEl);
      });
    }
  };
  openModal = (modal) => {
    modal.classList.add('active');
  };
  closeModal = (modal) => {
    modal.classList.remove('active');
  };
}
