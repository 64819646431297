import Accordion from 'accordion-js';
export function citylist() {
  try {
    const block = document.querySelectorAll('.js-collapse-list');
    for (const item of block) {
      new Accordion(item, {
        elementClass: 'js-collapse-item',
        triggerClass: 'js-collapse-trigger',
        panelClass: 'js-collapse-panel',
        onOpen: (currElement) => {
          const btn = currElement.querySelector('.js-collapse-trigger');
          btn.textContent = btn.getAttribute('data-visible-txt');
        },
        onClose: (currElement) => {
          const btn = currElement.querySelector('.js-collapse-trigger');
          btn.textContent = btn.getAttribute('data-hide-txt');
        },
      });
    }
  } catch (error) {
    //
  }
}
