export class SelectStyled {
  constructor(item) {
    this.selectEl = item;
    this.selectTag = null;
    this.optionsEl = null;
    this.dropDownEl = null;
    this.placeHolder = null;
    this.options = [];

    this.selectEl && this.init();
  }
  init = () => {
    this.selectTag = this.selectEl.querySelector('select');
    this.optionsEl = this.selectEl.querySelectorAll('option');
    this.dropDownEl = this.selectEl.querySelector('.calc-select__dropdown');
    this.placeHolder = this.selectEl.querySelector('.calc-select__placeholder');
    this.trigger = this.selectEl.querySelector('.js-calc-select-trigger');
    this.trigger.addEventListener('click', this.toggleDropDown);
    document.addEventListener('click', this.clickOutSide);
    this.renderOptions();
    this.defaultChoosedSetup();
  };
  renderOptions = () => {
    for (let index = 0; index < this.optionsEl.length; index++) {
      const item = this.optionsEl[index];
      const option = document.createElement('div');
      option.classList.add('calc-select__option');
      option.setAttribute('data-val', item.getAttribute('value'));
      option.setAttribute('data-index', index);
      option.textContent = item.textContent;
      this.dropDownEl.appendChild(option);
      this.options.push({
        title: item.textContent,
        value: item.getAttribute('value'),
        el: option,
      });
      option.addEventListener('click', () => {
        this.optionClickHandler(index);
      });
    }
  };
  defaultChoosedSetup = () => {
    this.setPlaceHolder(this.options[this.selectTag.selectedIndex].title);
    this.options[this.selectTag.selectedIndex].el.classList.add('active');
  };
  optionClickHandler = (selected) => {
    for (let index = 0; index < this.options.length; index++) {
      const item = this.options[index];
      if (index === selected) {
        !item.el.classList.contains('active') && item.el.classList.add('active');
        this.selectTag.selectedIndex = index;
        this.setPlaceHolder(item.title);
      } else {
        item.el.classList.remove('active');
      }
    }
  };
  setPlaceHolder = (text) => {
    this.placeHolder.textContent = text;
  };
  toggleDropDown = () => {
    this.trigger.classList.toggle('active');
  };

  clickOutSide = (e) => {
    const target = e.target;
    if (!target.isEqualNode(this.trigger) && !this.trigger.contains(target)) {
      this.trigger.classList.remove('active');
    }
  };
}
