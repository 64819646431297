import React, { memo, useRef } from 'react';
import { ralData } from './ralData';
const RalModal = memo(function Modal(props) {
  const { isOpen, onClose, onClick, choosedColor } = props;
  const contentNode = useRef(null);
  const closeModal = (e) => {
    if (!e.target.isEqualNode(contentNode.current) && !contentNode.current.contains(e.target)) {
      onClose();
    }
  };
  return (
    <div className={`tf-modal ${isOpen && '_active'}`}>
      <div className="tf-modal__layer" onClick={closeModal}></div>
      <div
        className="tf-modal__container tf-modal__container_vertical_center"
        onClickCapture={closeModal}
      >
        <div className="ral-modal" ref={contentNode}>
          <button className="ral-modal__close" onClick={onClose}>
            <svg
              className="ral-modal__icon"
              viewBox="0 0 21 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12.41 11L20.7 2.71C20.8638 2.5187 20.9494 2.27262 20.9397 2.02095C20.93 1.76927 20.8257 1.53053 20.6476 1.35244C20.4695 1.17434 20.2307 1.07001 19.9791 1.06029C19.7274 1.05057 19.4813 1.13617 19.29 1.3L11 9.59L2.71 1.29C2.5217 1.1017 2.2663 0.995908 2 0.995908C1.7337 0.995908 1.4783 1.1017 1.29 1.29C1.1017 1.4783 0.995908 1.7337 0.995908 2C0.995908 2.2663 1.1017 2.5217 1.29 2.71L9.59 11L1.29 19.29C1.18532 19.3796 1.1003 19.49 1.04027 19.614C0.980251 19.7381 0.946521 19.8732 0.941202 20.0109C0.935882 20.1487 0.959088 20.286 1.00936 20.4143C1.05964 20.5426 1.13589 20.6592 1.23335 20.7567C1.3308 20.8541 1.44735 20.9304 1.57568 20.9806C1.704 21.0309 1.84133 21.0541 1.97905 21.0488C2.11677 21.0435 2.25191 21.0097 2.37597 20.9497C2.50003 20.8897 2.61035 20.8047 2.7 20.7L11 12.41L19.29 20.7C19.4813 20.8638 19.7274 20.9494 19.9791 20.9397C20.2307 20.93 20.4695 20.8257 20.6476 20.6476C20.8257 20.4695 20.93 20.2307 20.9397 19.9791C20.9494 19.7274 20.8638 19.4813 20.7 19.29L12.41 11Z" />
            </svg>
          </button>
          <div className="ral-modal__title h1-title">Выберите цвет по шкале RAL</div>
          <div className="ral-modal__grid flex">
            {ralData.map((item) => (
              <div className="ral-modal__col" key={item.key}>
                <div
                  className="ral-modal__box"
                  onClick={() => {
                    onClick(item.name, item.color);
                    onClose();
                  }}
                >
                  <div
                    className={`ral-modal__border ${choosedColor === item.name && 'active'}`}
                    style={{ backgroundColor: item.color }}
                  ></div>
                  <div className="ral-modal__index">{item.name}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});
export default RalModal;
