import React, { memo } from 'react';
import { Door } from './NetsView/Door';
import { Standart } from './NetsView/Standart';
import { Eko } from './NetsView/Eko';
import { Skf } from './NetsView/Skf';
import { Trapezoid } from './NetsView/Trapezoid';
import { Provedal } from './NetsView/Provedal';
const ViewNets = memo(function (props) {
  const { id, color } = props;
  return (
    <>
      {id === 'st-1' && <Standart color={color} />}
      {id === 'st-2' && <Door color={color} />}
      {id === 'st-3' && <Door color={color} />}
      {id === 'st-4' && <Eko color={color} />}
      {id === 'st-5' && <Eko color={color} />}
      {id === 'st-6' && <Skf color={color} />}
      {id === 'st-7' && <Trapezoid color={color} />}
      {id === 'st-8' && <Provedal color={color} />}
    </>
  );
});
export default ViewNets;
