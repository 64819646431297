import Swiper, { Navigation, Pagination } from 'swiper';
export class Akcii {
  constructor() {
    this.block = document.querySelector('.js-akcii-slider');
    this.block2 = document.querySelectorAll('.js-akcii3-slider');
    this.init();
  }
  init = () => {
    if(this.block) {
      const slider = this.block.querySelector('.swiper');
      const prev = this.block.querySelector('.akcii__control_prev');
      const next = this.block.querySelector('.akcii__control_next');
      const pagination = this.block.querySelector('.akcii__pagination');
      new Swiper(slider, {
        slidesPerView: 1,
        spaceBetween: 30,
        modules: [Navigation, Pagination],
        navigation: {
          prevEl: prev,
          nextEl: next,
          disabledClass: 'disabled',
        },
        pagination: {
          el: pagination,
        },
        breakpoints: {
          992: {
            slidesPerView: 2,
            spaceBetween: 30,
          }
        },
      });
    }
    if(this.block2 && this.block2.length > 0) {
      for (const item of this.block2) {
        const slider = item.querySelector('.swiper');
        const prev = item.querySelector('.akcii__control_prev');
        const next = item.querySelector('.akcii__control_next');
        const pagination = item.querySelector('.akcii__pagination');
        new Swiper(slider, {
          slidesPerView: 1,
          spaceBetween: 18,
          modules: [Navigation, Pagination],
          navigation: {
            prevEl: prev,
            nextEl: next,
            disabledClass: 'disabled',
          },
          pagination: {
            el: pagination,
          },
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            }
          },
        });
      }
    }
  };
}
