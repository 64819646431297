import { FormValidation } from '@/js/formValidation';

export class CalcSize extends FormValidation {
  constructor() {
    super();
    this.block = document.querySelector('.js-calc-size');
    this.formConfig = null;
    this.outPutArea = null;
    this.checkBoxes = null;
    this.textInput = null;
    this.profile = null;
    this.glass = null;
    this.data = null;
    this.block && this.init();
  }
  init = () => {
    const self = this;
    this.outPutArea = this.block.querySelector('.js-calc-size-area');
    this.formConfig = this.block.querySelector('.js-calc-size-form');
    this.checkBoxes = this.block.querySelectorAll('.js-calc-size-checkbox');
    this.textInput = this.block.querySelectorAll('.js-calc-size-input');
    this.data = new FormData(this.formConfig);
    this.profile = new CalcSelect({
      el: self.block.querySelector('.js-calc-select-profile'),
      afterChange: (value) => {
        this.data.set('profile', value);
        this.changeOutPut();
      },
    });
    this.glass = new CalcSelect({
      el: self.block.querySelector('.js-calc-select-glass'),
      afterChange: (value) => {
        if (value.toLowerCase() == 'холодное остекление') {
          for (const item of this.profile.optionsEl) {
            if (
              item.getAttribute('data-val').toLowerCase() === 'профиль: rehau' ||
              item.getAttribute('data-val').toLowerCase() === 'профиль: melke'
            ) {
              item.style.cssText = 'display:none';
            }
          }
          if (
            this.profile.choosedValue.toLowerCase() === 'профиль: rehau' ||
            this.profile.choosedValue.toLowerCase() === 'профиль: melke'
          ) {
            this.profile.changeValue('', 'Выберите профиль');
          }
        } else {
          for (const item of this.profile.optionsEl) {
            if (
              item.getAttribute('data-val').toLowerCase() === 'профиль: rehau' ||
              item.getAttribute('data-val').toLowerCase() === 'профиль: melke'
            ) {
              item.style.cssText = '';
            }
          }
        }
        if (value.toLowerCase() == 'вторым контуром') {
          for (const item of this.profile.optionsEl) {
            if (
              item.getAttribute('data-val').toLowerCase() === 'профиль: alutech' ||
              item.getAttribute('data-val').toLowerCase() === 'профиль: сиал'
            ) {
              item.style.cssText = 'display:none';
            }
          }
          if (
            this.profile.choosedValue.toLowerCase() === 'профиль: alutech' ||
            this.profile.choosedValue.toLowerCase() === 'профиль: сиал'
          ) {
            this.profile.changeValue('', 'Выберите профиль');
          }
        } else {
          for (const item of this.profile.optionsEl) {
            if (
              item.getAttribute('data-val').toLowerCase() === 'профиль: alutech' ||
              item.getAttribute('data-val').toLowerCase() === 'профиль: сиал'
            ) {
              item.style.cssText = '';
            }
          }
        }
        this.changeOutPut();
      },
    });

    this.checkBoxSetup();
    this.textInputSetup();
    this.initOutPutFormValidation();
  };
  checkBoxSetup = () => {
    for (const item of this.checkBoxes) {
      item.addEventListener('change', this.changeOutPut);
    }
  };
  textInputSetup = () => {
    for (const item of this.textInput) {
      item.addEventListener('input', this.changeOutPut);
    }
  };
  changeOutPut = () => {
    this.data = new FormData(this.formConfig);
    let str = '';
    for (const [key, value] of this.data) {
      if (key === 'width' || key === 'height') {
        if (key === 'width') str += `Ширина: ${value}мм, `;
        if (key === 'height') str += `Высота: ${value}мм, `;
      } else {
        if (value !== '') str += `${value}, `;
      }
    }
    str = str.slice(0, -2);
    this.outPutArea.textContent = str;
    this.block.querySelector('.js-calc-size-config').value = str;
  };
  initOutPutFormValidation = () => {
    const block = document.querySelector('.js-calc-size');
    if (block) {
      const form = block.querySelector('.js-output-form');
      this.simpleCallBack(form, this.resetToDefault);
    }
  };
  resetToDefault = () => {
    this.formConfig.reset();
    this.glass.changeValue('', 'Выберите вид остекления');
    this.profile.changeValue('', 'Выберите профиль');
    this.changeOutPut();
  };
}
class CalcSelect {
  constructor(options) {
    const defaultOptions = {
      el: null, //html element
      afterChange: null, //(value)=>{}
    };
    this.options = { ...defaultOptions, ...options };
    this.block = this.options.el;
    this.trigger = null;
    this.placeHolder = null;
    this.optionsEl = null;
    this.input = null;
    this.choosedValue = null;
    this.block && this.init();
  }
  init = () => {
    this.trigger = this.block.querySelector('.js-calc-select-trigger');
    this.placeHolder = this.block.querySelector('.js-calc-select-placeholder');
    this.optionsEl = this.block.querySelectorAll('.js-calc-select-option');
    this.input = this.block.querySelector('.js-calc-select-input');
    this.trigger.addEventListener('click', this.toggleDropDown);
    document.addEventListener('click', this.clickOutSide);
    for (const item of this.optionsEl) {
      item.addEventListener('click', this.optionClick);
    }
    this.defaultChoosedSetup();
  };
  defaultChoosedSetup = () => {
    const value = this.optionsEl[0].getAttribute('data-val');
    const text = this.optionsEl[0].textContent;
    this.changeValue(value, text);
  };
  toggleDropDown = () => {
    this.trigger.classList.toggle('active');
  };
  optionClick = (e) => {
    const value = e.currentTarget.getAttribute('data-val');
    const text = e.currentTarget.textContent;
    this.changeValue(value, text);
  };
  changeValue = (value, text) => {
    const self = this;
    for (const item of this.optionsEl) {
      if (value === item.getAttribute('data-val')) {
        !item.classList.contains('active') && item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    }
    self.placeHolder.textContent = text;
    self.choosedValue = value;
    self.input.value = value;
    self.options.afterChange && self.options.afterChange(value);
  };
  clickOutSide = (e) => {
    const target = e.target;
    if (!target.isEqualNode(this.trigger) && !this.trigger.contains(target)) {
      this.trigger.classList.remove('active');
    }
  };
}
