import { CreateMobileSlider } from '@/js/helpers';
import { Pagination } from 'swiper';
export class TypesSlider {
  constructor() {
    this.blocks = document.querySelectorAll('.js-types-slider');
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.blocks) {
      new CreateMobileSlider({
        containerCssSelector: item,
        slideCssSelector: '.js-mobile-slide',
        sliderOptions: {
          spaceBetween: 20,
          modules: [Pagination],
          pagination: {
            el: '.swiper-pagination',
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
          },
        },
      });
    }
  };
}
