import React, { memo } from 'react';

const RadioImg = memo(function RadioImg(props) {
  const { checked, img, value, onClick } = props;
  const path = document.getElementById('calc-nets').getAttribute('data-path');
  return (
    <div className={`radio-img ${checked && 'active'}`} onClick={onClick}>
      <div className="radio-img__border">
        <img src={`${path}/${img}`} alt="" className="radio-img__img" />
      </div>
      <div className="radio-img__title">{value}</div>
    </div>
  );
});
export default RadioImg;
