import React from 'react';
import ReactDOM from 'react-dom/client';
import { CaclNets } from '@/blocks/calc-nets/CaclNets';
import '@/blocks/calc-nets/calc-nets.scss';

export default function initCalcNets() {
  if (document.getElementById('calc-nets')) {
    const root = ReactDOM.createRoot(document.getElementById('calc-nets'));
    root.render(
      <React.StrictMode>
        <CaclNets />
      </React.StrictMode>
    );
  }
}
