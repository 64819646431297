import Swiper, { Navigation, Pagination } from 'swiper';

export class blogSlider {
  constructor() {
    this.blocks = document.querySelectorAll('.js-blog-slider');
    if (this.blocks.length > 0) {
      this.init();
    }
  }
  init = () => {
    for (const item of this.blocks) {
      const type = item.getAttribute('data-type');
      const slider = item.querySelector('.swiper');
      if (type === 'blog') {
        const prev = item.querySelector('.blog__control_prev');
        const next = item.querySelector('.blog__control_next');
        const pagination = item.querySelector('.swiper-pagination');
        new Swiper(slider, {
          spaceBetween: 10,
          modules: [Navigation, Pagination],
          loop: true,
          grabCursor: true,
          navigation: {
            prevEl: prev,
            nextEl: next,
          },
          pagination: {
            el: pagination,
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 5,
              slidesPerGroup: 5,
              spaceBetween: 10,
            },
          },
        });
      } else {
        const prev = item.querySelector('.blog__control_prev');
        const next = item.querySelector('.blog__control_next');
        const pagination = item.querySelector('.swiper-pagination');
        new Swiper(slider, {
          modules: [Navigation, Pagination],
          spaceBetween: 10,
          loop: true,
          grabCursor: true,
          navigation: {
            prevEl: prev,
            nextEl: next,
          },
          pagination: {
            el: pagination,
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              spaceBetween: 10,
            },
            650: {
              slidesPerView: 2,
              slidesPerGroup: 3,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 3,
              slidesPerGroup: 4,
            },
            1200: {
              slidesPerView: 5,
              slidesPerGroup: 5,
            },
          },
        });
      }
    }
  };
}
