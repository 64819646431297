import React, { useState, useCallback, useEffect } from 'react';
import { onlyNumbers } from '@/js/helpers';
import { profileData } from './profile-data';
import Select from '@/components/select/Select';

export const CalcPrice = () => {
  const path = document.getElementById('calc-price').getAttribute('data-path');
  const profileKey = document.getElementById('calc-price').getAttribute('data-profile');
  const profileType = profileData[profileKey.toLowerCase()];

  const glassType = [
    { id: 'g-1', title: 'Стандартный', value: 'Стандартный' },
    { id: 'g-2', title: 'Теплосберегающий', value: 'Теплосберегающий' },
    { id: 'g-3', title: 'Солнцезащитный', value: 'Солнцезащитный' },
    { id: 'g-4', title: 'Шумозащитный', value: 'Шумозащитный' },
  ];
  const windowsillSize = [
    { id: 'w-1', title: 'Нет', value: 'нет' },
    { id: 'w-2', title: '150мм', value: '150мм' },
    { id: 'w-3', title: '200мм', value: '200мм' },
    { id: 'w-4', title: '250мм', value: '250мм' },
    { id: 'w-5', title: '300мм', value: '300мм' },
    { id: 'w-6', title: '350мм', value: '350мм' },
    { id: 'w-7', title: '400мм', value: '400мм' },
    { id: 'w-8', title: '450мм', value: '450мм' },
    { id: 'w-9', title: '500мм', value: '500мм' },
  ];
  const lowTide = [
    { id: 'l-1', title: 'Нет', value: 'нет' },
    { id: 'l-2', title: 'менее 100мм', value: 'менее 100мм' },
    { id: 'l-3', title: '100-150мм', value: '100-150мм' },
    { id: 'l-4', title: '200-250мм', value: '200-250мм' },
    { id: 'l-5', title: '250-400мм', value: '250-400мм' },
  ];
  const balconyFlap = [
    { id: 'bf-1', title: 'Глухая', value: 'Глухая', img: 'balcon.svg' },
    { id: 'bf-2', title: 'Поворотная', value: 'Поворотная', img: 'balcon-povorotnoe.svg' },
    {
      id: 'bf-3',
      title: 'Поворотно-откидная',
      value: 'Поворотно-откидная',
      img: 'balcon-povorotno-otkidnoe.svg',
    },
    { id: 'bf-4', title: 'Откидная', value: 'Откидная', img: 'balcon-otkidnoe.svg' },
  ];
  const windowFlapLeft = [
    {
      id: 'wf-1',
      title: 'Глухая',
      value: 'Глухая',
      img: 'gluhoe.svg',
    },
    {
      id: 'wf-2',
      title: 'Поворотная',
      value: 'Поворотная',
      img: 'povorotnoe-l.svg',
    },
    {
      id: 'wf-3',
      title: 'Поворотно-откидная',
      value: 'Поворотно-откидная',
      img: 'povorotno-otkidnoe-l.svg',
    },
    {
      id: 'wf-4',
      title: 'Откидная',
      value: 'Откидная',
      img: 'otkidnoe-l.svg',
    },
  ];

  const windowFlapRight = [
    { id: 'wfr-1', title: 'Глухая', value: 'Глухая', img: 'gluhoe.svg' },
    { id: 'wfr-2', title: 'Поворотная', value: 'Поворотная', img: 'povorotnoe-p.svg' },
    {
      id: 'wfr-3',
      title: 'Поворотно-откидная',
      value: 'Поворотно-откидная',
      img: 'povorotno-otkidnoe-p.svg',
    },
    { id: 'wfr-4', title: 'Откидная', value: 'Откидная', img: 'otkidnoe-p.svg' },
  ];
  const [config, setConfig] = useState({
    width: 2000,
    height: 2000,
    flapIndex: 1,
    frame: 1,
    flapFirst: windowFlapRight[0],
    flapSecond: windowFlapRight[0],
    flapThird: windowFlapRight[0],
    profileType: profileType[0],
    glassType: glassType[0],
    windowsillSize: windowsillSize[0],
    lowTide: lowTide[0],
  });
  const [outPut, setOutPut] = useState([]);
  useEffect(() => {
    if (config.frame > 3) {
      let findedValue = null;
      balconyFlap.forEach((item) => {
        if (item.value === config.flapFirst.value) {
          findedValue = item;
        }
      });

      setConfig((prev) => ({
        ...prev,
        flapFirst: findedValue,
      }));
    } else {
      let findedValue = null;
      if (config.frame > 1) {
        windowFlapLeft.forEach((item) => {
          if (item.value === config.flapFirst.value) {
            findedValue = item;
          }
        });
      } else {
        windowFlapRight.forEach((item) => {
          if (item.value === config.flapFirst.value) {
            findedValue = item;
          }
        });
      }
      setConfig((prev) => ({
        ...prev,
        flapFirst: findedValue,
      }));
    }
  }, [config.frame]);

  const changeWidth = (e) => {
    const value = onlyNumbers(e.target.value);
    setConfig((prev) => ({
      ...prev,
      width: value,
    }));
  };
  const changeHeight = (e) => {
    const value = onlyNumbers(e.target.value);
    setConfig((prev) => ({
      ...prev,
      height: value,
    }));
  };
  const changeFrame = useCallback((e) => {
    setConfig((prev) => ({
      ...prev,
      frame: Number(e.target.value),
    }));
  }, []);
  const changeProfileType = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      profileType: value,
    }));
  }, []);
  const changeGlassType = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      glassType: value,
    }));
  }, []);
  const changeWindowsillSize = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      windowsillSize: value,
    }));
  }, []);
  const changelowTide = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      lowTide: value,
    }));
  }, []);
  const changeFirstFlap = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      flapFirst: value,
    }));
  }, []);
  const changeSecondFlap = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      flapSecond: value,
    }));
  }, []);
  const changeThirdFlap = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      flapThird: value,
    }));
  }, []);
  const checkOutBtnClick = () => {
    const str = outPut.join(';\n');
    const modal = document.getElementById('calc-nets-order');
    const textArea = modal.querySelector('.js-calc-nets-output');
    textArea.value = str;
    modal.classList.add('_active');
    document.body.style.overflow = 'hidden';
  };
  useEffect(() => {
    const array = [];
    array.push(`Размеры окна:${config.width} х ${config.height} мм`);
    switch (config.frame) {
      case 2:
        array.push(
          `Конфигурация: Двухстворчатое окно (1ст. - ${config.flapFirst.value}, 2ст. - ${config.flapSecond.value})`
        );
        break;
      case 3:
        array.push(
          `Конфигурация: Трехстворчатое окно (1ст. - ${config.flapFirst.value}, 2ст. - ${config.flapSecond.value}, 3ст. - ${config.flapThird.value})`
        );
      case 4:
        array.push(
          `Конфигурация: Балконный блок (1ст. - ${config.flapFirst.value}, 2ст. - ${config.flapSecond.value}, 3ст. - ${config.flapThird.value})`
        );
      default:
        array.push(`Конфигурация: Одностворчатое окно (1ст. - ${config.flapFirst.value})`);
        break;
    }
    array.push(`Тип профиля:${config.profileType.value}`);
    array.push(`Стеклопакет:${config.glassType.value}`);
    array.push(`Ширина подоконника:${config.windowsillSize.value}`);
    array.push(`Ширина отлива:${config.lowTide.value}`);
    setOutPut(array);
  }, [config]);

  return (
    <div className="calc-price">
      <div className="calc-price__grid flex">
        <div className="calc-price__main">
          <div className="calc-price__group">
            <div className="calc-price__type">
              <label className="calc-price__switch">
                <input
                  className="calc-price__switch-radio"
                  type="radio"
                  onChange={changeFrame}
                  value={1}
                  checked={config.frame == 1}
                />
                <svg
                  className="calc-price__switch-icon"
                  viewBox="0 0 70 51"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 3C0 1.34315 1.34315 0 3 0H26C27.6569 0 29 1.34315 29 3V48C29 49.6569 27.6569 51 26 51H3C1.34315 51 0 49.6569 0 48V3Z" />
                </svg>
              </label>
              <label className="calc-price__switch">
                <input
                  className="calc-price__switch-radio"
                  type="radio"
                  name="frame"
                  onChange={changeFrame}
                  value={2}
                  checked={config.frame == 2}
                />
                <svg
                  className="calc-price__switch-icon"
                  viewBox="0 0 70 51"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 3C0 1.34315 1.34315 0 3 0H26C27.6569 0 29 1.34315 29 3V48C29 49.6569 27.6569 51 26 51H3C1.34315 51 0 49.6569 0 48V3Z" />
                  <path d="M31 3C31 1.34315 32.3431 0 34 0H57C58.6569 0 60 1.34315 60 3V48C60 49.6569 58.6569 51 57 51H34C32.3431 51 31 49.6569 31 48V3Z" />
                </svg>
              </label>
              <label className="calc-price__switch">
                <input
                  className="calc-price__switch-radio"
                  type="radio"
                  onChange={changeFrame}
                  value={3}
                  checked={config.frame == 3}
                />
                <svg
                  className="calc-price__switch-icon"
                  viewBox="0 0 70 39"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 3C0 1.34315 1.34315 0 3 0H19C20.6569 0 22 1.34315 22 3V36C22 37.6569 20.6569 39 19 39H3C1.34315 39 0 37.6569 0 36V3Z" />
                  <path d="M24 3C24 1.34315 25.3431 0 27 0H43C44.6569 0 46 1.34315 46 3V36C46 37.6569 44.6569 39 43 39H27C25.3431 39 24 37.6569 24 36V3Z" />
                  <path d="M48 3C48 1.34315 49.3431 0 51 0H67C68.6569 0 70 1.34315 70 3V36C70 37.6569 68.6569 39 67 39H51C49.3431 39 48 37.6569 48 36V3Z" />
                </svg>
              </label>
              <label className="calc-price__switch">
                <input
                  className="calc-price__switch-radio"
                  type="radio"
                  onChange={changeFrame}
                  value={4}
                  checked={config.frame == 4}
                />
                <svg
                  className="calc-price__switch-icon"
                  viewBox="0 0 70 62"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 3C0 1.34315 1.34315 0 3 0H19C20.6569 0 22 1.34315 22 3V59C22 60.6569 20.6569 62 19 62H3C1.34315 62 0 60.6569 0 59V3Z" />
                  <path d="M24 3C24 1.34315 25.3431 0 27 0H43C44.6569 0 46 1.34315 46 3V36C46 37.6569 44.6569 39 43 39H27C25.3431 39 24 37.6569 24 36V3Z" />
                  <path d="M48 3C48 1.34315 49.3431 0 51 0H67C68.6569 0 70 1.34315 70 3V36C70 37.6569 68.6569 39 67 39H51C49.3431 39 48 37.6569 48 36V3Z" />
                </svg>
              </label>
            </div>
            <div
              className={`calc-price__frame calc-price__frame_type_${
                config.frame > 2 ? '3' : config.frame
              }`}
            >
              <input
                type="text"
                className="calc-price__sizer calc-price__sizer_type_width"
                onChange={changeWidth}
                value={config.width}
              />
              <input
                type="text"
                className="calc-price__sizer calc-price__sizer_type_height"
                onChange={changeHeight}
                value={config.height}
              />
              <div className="calc-price__box">
                <img className="_fw" src={`${path}/${config.flapFirst.img}`} alt="" />
              </div>
              {config.frame >= 2 && (
                <div className="calc-price__box">
                  <img className="_fw" src={`${path}/${config.flapSecond.img}`} alt="" />
                </div>
              )}
              {config.frame >= 3 && (
                <div className="calc-price__box">
                  <img className="_fw" src={`${path}/${config.flapThird.img}`} alt="" />
                </div>
              )}
            </div>
          </div>
          <div
            className={`calc-price__settings ${
              config.frame > 2 ? 'calc-price__settings_type_3' : ''
            }`}
          >
            <div className="calc-price__item">
              {config.frame > 3 ? (
                <Select
                  options={balconyFlap}
                  label={'1 створка:'}
                  onChange={changeFirstFlap}
                  value={config.flapFirst}
                />
              ) : (
                <Select
                  options={config.frame > 1 ? windowFlapLeft : windowFlapRight}
                  label={'1 створка:'}
                  onChange={changeFirstFlap}
                  value={config.flapFirst}
                />
              )}
            </div>
            {config.frame > 1 && (
              <div className="calc-price__item">
                <Select
                  options={windowFlapRight}
                  label={'2 створка:'}
                  onChange={changeSecondFlap}
                  value={config.flapSecond}
                />
              </div>
            )}
            {config.frame > 2 && (
              <div className="calc-price__item">
                <Select
                  options={windowFlapRight}
                  label={'3 створка:'}
                  onChange={changeThirdFlap}
                  value={config.flapThird}
                />
              </div>
            )}
          </div>
        </div>
        <div className="calc-price__aside">
          <div className="calc-price__select">
            <Select
              options={profileType}
              label={'Тип профиля:'}
              onChange={changeProfileType}
              value={config.profileType}
            />
          </div>
          <div className="calc-price__select">
            <Select
              options={glassType}
              label={'Стеклопакет:'}
              onChange={changeGlassType}
              value={config.glassType}
            />
          </div>
          <div className="calc-price__select">
            <Select
              options={windowsillSize}
              label={'Ширина подоконника:'}
              onChange={changeWindowsillSize}
              value={config.windowsillSize}
            />
          </div>
          <div className="calc-price__select">
            <Select
              options={lowTide}
              label={'Ширина отлива:'}
              onChange={changelowTide}
              value={config.lowTide}
            />
          </div>
          <div className="calc-price__plate">
            <div className="calc-price__output">
              Размеры окна:{' '}
              <span className="calc-price__valiable">
                {config.width} х {config.height} мм
              </span>
            </div>
            <div className="calc-price__output">
              Конфигурация:{' '}
              <span className="calc-price__valiable">
                {config.frame === 1 && `Одностворчатое окно (1ст. - ${config.flapFirst.value})`}
                {config.frame === 2 &&
                  `Двухстворчатое окно (1ст. - ${config.flapFirst.value}, 2ст. - ${config.flapSecond.value})`}
                {config.frame === 3 &&
                  `Трехстворчатое окно (1ст. - ${config.flapFirst.value}, 2ст. - ${config.flapSecond.value}, 3ст. - ${config.flapThird.value})`}
                {config.frame === 4 &&
                  `Балконный блок (1ст. - ${config.flapFirst.value}, 2ст. - ${config.flapSecond.value}, 3ст. - ${config.flapThird.value})`}
              </span>
            </div>
            <div className="calc-price__output">
              Тип профиля: <span className="calc-price__valiable">{config.profileType.value}</span>
            </div>
            <div className="calc-price__output">
              Стеклопакет: <span className="calc-price__valiable">{config.glassType.value}</span>
            </div>
            <div className="calc-price__output">
              Ширина подоконника:{' '}
              <span className="calc-price__valiable">{config.windowsillSize.value}</span>
            </div>
            <div className="calc-price__output">
              Ширина отлива: <span className="calc-price__valiable">{config.lowTide.value}</span>
            </div>
            <div className="calc-price__footer" onClick={checkOutBtnClick}>
              <button className="calc-price__btn btn-base btn-base_secondary btn-base_md">
                Заказать
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
