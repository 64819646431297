import { ClassicTabs } from '@/js/tabs';

export class DeadLInes {
  constructor() {
    this.block = document.querySelector('.deadlines');
    this.block && this.init();
  }
  init = () => {
    new ClassicTabs({
      blockSelector: this.block,
    });
  };
}
