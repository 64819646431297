import { CreateMobileSlider } from '@/js/helpers';
import { ClassicTabs } from '@/js/tabs';
import { Pagination } from 'swiper';

export class ShowCase {
  constructor() {
    this.blocks = document.querySelectorAll('.js-showcase');
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.blocks) {
      const tabsWrapper = item.querySelector('.js-showcase-tabs');
      new ClassicTabs({
        blockSelector: tabsWrapper,
        controlSelector: '.js-showcase-tabs-control',
        viewSelector: '.js-showcase-tabs-view',
      });
      const viewEl = item.querySelectorAll('.showcase__grid');
      for (const item of viewEl) {
        // const pagination = item.querySelector('.swiper-pagination');
        new CreateMobileSlider({
          containerCssSelector: item,
          slideCssSelector: '.js-mobile-slide',
          sliderOptions: {
            spaceBetween: 20,
            modules: [Pagination],
            pagination: {
              el: '.swiper-pagination',
            },
            breakpoints: {
              320: {
                slidesPerView: 1,
              },
              520: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
            },
          },
        });
      }
    }
  };
}
