import { CreateMobileSlider } from '@/js/helpers';
import { Pagination } from 'swiper';
export class PriceBlock {
  constructor() {
    this.block = document.querySelector('.js-price-slider');
    this.block && this.init();
  }
  init = () => {
    new CreateMobileSlider({
      containerCssSelector: this.block,
      slideCssSelector: '.js-price-slide',
      paginationClass: 'swiper-pagination',
      //ширина при которой появится слайдер
      mobileBreakPoint: '650',
      sliderOptions: {
        spaceBetween: 15,
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          380: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 3,
          },
        },
      },
    });
  };
}
