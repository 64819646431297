import axios from 'axios';
import SimpleBar from 'simplebar';
import Accordion from 'accordion-js';

export class Contacts {
  constructor() {
    this.block = document.querySelector('.contact');
    this.block && this.init();
  }
  init = () => {
    new Map();
    this.initSimpleBar();
  };
  initSimpleBar = () => {
    const box = this.block.querySelector('.contact__content');
    if (box) {
      new SimpleBar(box, {
        autoHide: false,
      });
    }
    new Accordion(this.block.querySelector('.js-accordion'), {
      elementClass: 'contact__content',
      triggerClass: 'contact__trigger',
      panelClass: 'contact__slide',
      onOpen: (currElement) => {
        const btn = currElement.querySelector('.contact__trigger');
        btn.textContent = 'Скрыть';
      },
      onClose: (currElement) => {
        const btn = currElement.querySelector('.contact__trigger');
        btn.textContent = 'Показать ещё';
      },
    });
  };
}
class Map {
  constructor(options) {
    const defaultOptions = {
      mapContainerCssSelector: '.js-map-place',
      multiMapCssSelector: null,
      centerLatitude: 55.726411,
      centerLong: 37.669361,
      markLatitude: 55.726411,
      markLong: 37.669361,
      apiKey: '50ccb9be-c80e-436b-82ae-a698da180108',
      styles: [],
      iconPath: '',
    };
    this.options = { ...defaultOptions, ...options };
    this.mapUrl = `https://api-maps.yandex.ru/2.1/?apikey=${this.options.apiKey}&lang=ru_RU`;
    this.mapContainerEl = document.querySelector(this.options.mapContainerCssSelector);
    this.observer = null;
    this.afterInitCallback = null;
    this.mapContainerEl && this.initSingleMap();
  }
  initSingleMap = () => {
    this.setObserverOnMap();
  };

  setObserverOnMap = () => {
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        for (const item of entries) {
          if (item.isIntersecting) {
            this.loadMapScript();
            this.observer.unobserve(this.mapContainerEl);
          }
        }
      },
      { rootMargin: '30%', threshold: 1.0 }
    );
    this.observer.observe(this.mapContainerEl);
  };
  initMap = async () => {
    const self = this;
    const res = await self.getData();
    var myMap = new ymaps.Map(self.mapContainerEl, {
      // Координаты центра карты.
      // Порядок по умолчанию: «широта, долгота».
      // Чтобы не определять координаты центра карты вручную,
      // воспользуйтесь инструментом Определение координат.
      center: [res.center.lat, res.center.long],
      // Уровень масштабирования. Допустимые значения:
      // от 0 (весь мир) до 19.
      zoom: 9,
    });
    for (let index = 0; index < res.places.length; index++) {
      const element = res.places[index];
      const myPlacemark = new ymaps.Placemark(
        element,
        {
          // hintContent: 'Собственный значок метки',
          // balloonContent: 'Это красивая метка',
        },
        {
          // Опции.
          // Необходимо указать данный тип макета.
          iconLayout: 'default#image',
          // Своё изображение иконки метки.
          iconImageHref: self.mapContainerEl.getAttribute('data-icon'),
          // Размеры метки.
          iconImageSize: [40, 40],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [0, 0],
        }
      );
      myMap.geoObjects.add(myPlacemark);
      const mobileWidthMediaQuery = window.matchMedia('(max-width: 500px)');
      if(mobileWidthMediaQuery.matches){
        myMap.setZoom(7);
        myMap.setCenter([59.938784, 30.314997])
      }
    }
    this.afterInitCallback && this.afterInitCallback();
  };
  getData = async () => {
    const res = await axios(this.mapContainerEl.getAttribute('data-json'));
    return res.data;
  };
  loadMapScript = () => {
    const self = this;
    const script = document.createElement('script');
    script.onload = function () {
      ymaps.ready(self.initMap);
    };
    script.src = this.mapUrl;
    document.getElementsByTagName('head')[0].appendChild(script);
  };
}
