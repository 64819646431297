import { CreateMobileSlider } from '@/js/helpers';
import { Pagination } from 'swiper';

export class Usage {
  constructor() {
    this.blocks = document.querySelectorAll('.js-usage-slider');
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.blocks) {
      new CreateMobileSlider({
        containerCssSelector: item,
        slideCssSelector: '.js-usage-slide',
        paginationClass: 'swiper-pagination',
        //ширина при которой появится слайдер
        mobileBreakPoint: '768',
        sliderOptions: {
          modules: [Pagination],
          spaceBetween: 15,
          pagination: {
            el: '.swiper-pagination',
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 2,
            },
          },
        },
      });
    }
  };
}
