import { ClassicTabs } from '@/js/tabs';

export class NavBar {
  constructor() {
    this.navbarData = document.querySelector('#navbar');
    this.firstLevelItems = null;
    this.navbarEl = null;
    this.navbarData && this.init();
  }
  init = () => {
    this.renderWrapper();
    this.createData();
    this.renderItems();
    new ClassicTabs({
      blockSelector: '.navbar',
      controlSelector: '.js-navtab-control',
      viewSelector: '.js-navtab-view',
      setupOpenFirstTab: false,
      toggle: true,
    });
  };
  createData = () => {
    this.firstLevelItems = this.navbarData.querySelectorAll(':scope > li');
  };
  renderWrapper = () => {
    this.navbarData.insertAdjacentHTML(
      'afterend',
      `
      <nav class="navbar">
      <div class="container">
        <div class="navbar__list flex"></div>
      </div>
    </nav>
    `
    );
    this.navbarEl = document.querySelector('.navbar');
  };
  renderItems = () => {
    const list = this.navbarEl.querySelector('.navbar__list');
    for (const item of this.firstLevelItems) {
      const link = item.querySelector(':scope > a');
      const listTwoDepth = item.querySelector(':scope > ul');
      if (listTwoDepth) {
        const itemEl = document.createElement('div');
        itemEl.classList.add('navbar__item');
        const linkEl = document.createElement('a');
        linkEl.classList.add('navbar__link');
        const linkTitle = document.createTextNode(link.textContent);
        linkEl.setAttribute('href', link.getAttribute('href'));
        linkEl.appendChild(linkTitle);
        itemEl.appendChild(linkEl);
        itemEl.insertAdjacentHTML(
          'beforeend',
          `<button class="navbar__switch">
        <svg class="navbar__arrow navbar__arrow_fill_white" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.49986 7L0.602744 0.250001L8.39697 0.25L4.49986 7Z"/>
        </svg>
      </button>`
        );
        const dropdown = document.createElement('div');
        dropdown.classList.add('navbar__dropdown');
        const navbarPlate = document.createElement('div');
        navbarPlate.classList.add('navbar__subnav');
        dropdown.appendChild(navbarPlate);
        itemEl.appendChild(dropdown);
        list.appendChild(itemEl);
        this.renderSubItems(navbarPlate, listTwoDepth);
        itemEl.addEventListener('mouseenter', () => {
          this.mouseOverFirstLevel(itemEl, dropdown);
        });
        itemEl.addEventListener('mouseleave', () => {
          this.mouseLeaveFirstLevel(itemEl, dropdown);
        });
      } else {
        list.insertAdjacentHTML(
          'beforeend',
          `<div class="navbar__item">
            <a href="${link.getAttribute('href')}" class="navbar__link">${link.textContent}</a>
          </div>`
        );
      }
    }
  };
  renderSubItems = (plate, list) => {
    const items = list.querySelectorAll(':scope > li');
    const aside = document.createElement('div');
    const main = document.createElement('div');
    aside.classList.add('navbar__aside');
    main.classList.add('navbar__main');
    plate.appendChild(aside);
    plate.appendChild(main);
    for (const item of items) {
      const childList = item.querySelector(':scope > ul');
      const link = item.querySelector('a');
      if (childList) {
        const childItems = childList.querySelectorAll('li');
        const key = this.createRandomString(10);
        aside.insertAdjacentHTML(
          'beforeend',
          `
        <div class="navbar__control js-navtab-control" data-tab="tab-${key}">
										<a href="${link.getAttribute('href')}" class="navbar__link">${link.textContent}</a>
										<button class="navbar__switch ">
											<svg class="navbar__arrow navbar__arrow_fill_primary" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M4.49986 7L0.602744 0.250001L8.39697 0.25L4.49986 7Z"/>
											</svg>
										</button>
									</div>`
        );
        const itemTab = document.createElement('div');
        itemTab.classList.add('navbar__children', 'js-navtab-view');
        itemTab.setAttribute('data-tab', `tab-${key}`);
        main.appendChild(itemTab);
        for (const item of childItems) {
          const subLink = item.querySelector('a');
          itemTab.insertAdjacentHTML(
            'beforeend',
            `
          <div class="navbar__child">
										<a class="navbar__child-link" href="${subLink.getAttribute('href')}">${subLink.textContent}</a>
									</div>
          `
          );
        }
      } else {
        aside.insertAdjacentHTML(
          'beforeend',
          `
        <div class="navbar__control">
					<a href="${link.getAttribute('href')}" class="navbar__link">${link.textContent}</a>
				</div>`
        );
      }
    }
  };
  createRandomString = (length) => {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  mouseOverFirstLevel = (item, subnav) => {
    item.classList.add('active');
    const delta = window.innerWidth - (subnav.getBoundingClientRect().x + 900);
    if (delta < 0) {
      subnav.style.transform = `translate(${delta}px, 100%)`;
    } else {
      subnav.style.transform = `translate(0, 100%) `;
    }
  };
  mouseLeaveFirstLevel = (item) => {
    item.classList.remove('active');
  };
}
