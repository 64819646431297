import Swiper, { Controller, EffectFade, Navigation } from 'swiper';

export class AntSlider {
  constructor() {
    this.block = document.querySelector('.js-ant-slider');
    this.iconSlider = null;
    this.textSlider = null;
    this.pageCurrent = null;
    this.proggressEl = null;
    this.block && this.init();
  }
  init = () => {
    this.pageCurrent = this.block.querySelector('.ant-slider__page_current');
    this.proggressEl = this.block.querySelector('.ant-slider__proggress');
    this.initTextSlider();
    this.initIconSlider();
  };
  initIconSlider = () => {
    const self = this;
    const prev = self.block.querySelector('.js-ant-prev');
    const next = self.block.querySelector('.js-ant-next');

    self.iconSlider = new Swiper(self.block.querySelector('.js-ant-icon-slider'), {
      modules: [EffectFade, Navigation, Controller],
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        prevEl: prev,
        nextEl: next,
        disabledClass: 'disabled',
      },
      controller: {
        control: self.textSlider,
      },
      on: {
        afterInit: self.afterInitSlider,
        slideChange: self.slideChange,
      },
    });
  };
  initTextSlider = () => {
    this.textSlider = new Swiper(this.block.querySelector('.js-ant-text-slider'), {
      modules: [EffectFade, Controller],
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    });
  };
  afterInitSlider = (swiper) => {
    const pageTotal = this.block.querySelector('.ant-slider__page_total');
    this.pageCurrent.textContent = swiper.activeIndex + 1;
    pageTotal.textContent = `/${swiper.slides.length}`;
    this.proggressEl.style.cssText = `stroke-dashoffset: ${
      620 - ((swiper.activeIndex + 1) * 620) / swiper.slides.length
    };`;
  };
  slideChange = (swiper) => {
    this.pageCurrent.textContent = swiper.activeIndex + 1;
    this.proggressEl.style.cssText = `stroke-dashoffset: ${
      620 - ((swiper.activeIndex + 1) * 620) / swiper.slides.length
    };`;
  };
}
