import {
    CreateMobileSlider
} from '@/js/helpers';
import {
    Pagination
} from 'swiper';

export class PlasticWSlider {
    constructor() {
        this.blocks = document.querySelectorAll('.js-plastic-w-cards');
        this.blocks.length > 0 && this.init();
    }
    init = () => {
        for (const item of this.blocks) {
            const viewEl = item.querySelectorAll('.plastic-w__grid');
            for (const item of viewEl) {
                new CreateMobileSlider({
                containerCssSelector: item,
                slideCssSelector: '.js-mobile-slide',
                sliderOptions: {
                    spaceBetween: 20,
                    modules: [Pagination],
                    pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    },
                    breakpoints: {
                        // when window width is >= 320px
                        320: {
                          slidesPerView: 1
                        },
                        // when window width is >= 640px
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 20
                        }
                      },
                },
                });
            }
        }
    }
}