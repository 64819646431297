import { ClassicTabs } from '@/js/tabs';
import { SliderWithCustomDots } from '@/js/helpers';
import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';

export class GalleryBlock {
  constructor() {
    this.blocks = document.querySelectorAll('.js-gallery');
    this.block = document.querySelectorAll('.primer__slider');
    this.blocks2 = document.querySelectorAll('.pr__slider');
    this.block2 = document.querySelectorAll('.gallery-card-slider');
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    if(this.blocks) {
      for (const item of this.blocks) {
        const switchEl = item.querySelector('.js-gallery-switch');
        const viewEl = item.querySelectorAll('.js-tab-view');
        new ClassicTabs({
          blockSelector: item,
          controlSelector: '.js-tab-control',
          viewSelector: '.js-tab-view',
          scrollIntoView: false,
          setupOpenFirstTab: true,
          toogle: false,
        });
        for (const view of viewEl) {
          const slider = view.querySelector('.js-gallery-slider');
          const dots = view.querySelectorAll('.js-gallery-dots');
          new SliderWithCustomDots({
            sliderEl: slider, // single element
            dots: dots, // collection of element
            prevEl: null, // single element
            nextEl: null, // single element
            parentDots: null,
            sliderSpeed: 600,
            slideChangeCallback: null,
            breakpoints: {
              320: {
                slidesPerView: 1,
                //   spaceBetween: 20,
              },
              651: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
            },
          });
        }
      }
    }

    if(this.block) {
      for (let i = 0; i < this.block.length; i++) {
        let swiperThumbs = new Swiper(document.querySelector(`.primer__slider-${i} .primer-thumbs`), {
          spaceBetween: 6,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesProgress: true,
        });
        let swiperMain = new Swiper(document.querySelector(`.primer__slider-${i} .primer-main`), {
          modules: [Thumbs],
          spaceBetween: 10,
          thumbs: {
            swiper: swiperThumbs,
          },
        });
      }
    }

    if(this.blocks2) {
      for (let i = 0; i < this.blocks2.length; i++) {
        let swiperThumbs = new Swiper(document.querySelector(`.pr__slider-${i} .pr-thumbs`), {
          spaceBetween: 6,
          slidesPerView: 4,
          direction: 'horizontal',
          breakpoints: {
            768: {
              direction: 'vertical',
            },
          },
        });
        let swiperMain = new Swiper(document.querySelector(`.pr__slider-${i} .pr-main`), {
          modules: [Thumbs],
          spaceBetween: 10,
          thumbs: {
            swiper: swiperThumbs,
          },
        });
      }
    }

    if(this.block2) {
      for (const item of this.block2) {
        const sliderEl = item.querySelector('.swiper');
        const prevEl = item.querySelector('.reviews__control_prev');
        const nextEl = item.querySelector('.reviews__control_next');
        const pagination = item.querySelector('.swiper-pagination');
        const slider = new Swiper(sliderEl, {
          modules: [Navigation, Pagination],
          spaceBetween: 20,
          navigation: {
            prevEl: prevEl,
            nextEl: nextEl,
            disabledClass: 'disabled',
          },
          pagination: {
            el: pagination,
          },
          breakpoints: {
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          },
        });
      }
    }
  };
}
