import React, { useState, useCallback, useEffect } from 'react';
import { onlyNumbers } from '@/js/helpers';
import Select from '@/components/select/Select';
import Switch from '@/blocks/calc-nets/Switch';
import RadioImg from '@/blocks/calc-nets/RadioImg';
import RalModal from '@/blocks/calc-nets/RalModal';
import ViewNets from '@/blocks/calc-nets/ViewNets';

export const CaclNets = () => {
  const path = document.getElementById('calc-nets').getAttribute('data-path');
  const types = [
    {
      id: 'st-1',
      title: 'Рамочная “Стандарт”',
      value: 'Рамочная “Стандарт”',
      img: 'standart.png',
      maxWidth: 2000,
      minWidth: 200,
      maxHeight: 3000,
      minHeight: 200,
    },
    {
      id: 'st-2',
      title: 'Дверная 25мм',
      value: 'Дверная 25мм',
      img: 'door-sm.png',
      maxWidth: 2000,
      minWidth: 200,
      maxHeight: 3000,
      minHeight: 200,
    },
    {
      id: 'st-3',
      title: 'Дверная 52мм',
      value: 'Дверная 52мм',
      img: 'door-lg.png',
      maxWidth: 2000,
      minWidth: 400,
      maxHeight: 3000,
      minHeight: 500,
    },
    {
      id: 'st-4',
      title: 'Рулонная “ЭКО”',
      value: 'Рулонная “ЭКО”',
      img: 'eko.png',
      maxWidth: 2000,
      minWidth: 400,
      maxHeight: 2500,
      minHeight: 400,
    },
    {
      id: 'st-5',
      title: 'Вставная SKF',
      value: 'Вставная SKF',
      img: 'skf.png',
      maxWidth: 1600,
      minWidth: 200,
      maxHeight: 3000,
      minHeight: 200,
    },
    {
      id: 'st-6',
      title: 'Плиссе SKF',
      value: 'Плиссе SKF',
      img: 'skf.png',
      maxWidth: 6000,
      minWidth: 400,
      maxHeight: 3000,
      minHeight: 400,
    },
    {
      id: 'st-7',
      title: 'Трапециевидная',
      value: 'Трапециевидная',
      img: 'trapezoid.png',
      class: 'calc-nets__sizer_type_trapezoid',
      maxWidth: 1600,
      minWidth: 200,
      maxHeight: 3000,
      minHeight: 200,
    },
    {
      id: 'st-8',
      title: 'Раздвижная Provedal',
      value: 'Раздвижная Provedal',
      img: 'provedal.png',
      class: 'calc-nets__sizer_type_provedal',
      maxWidth: 1600,
      minWidth: 300,
      maxHeight: 2500,
      minHeight: 300,
    },
  ];
  const coverage = [
    {
      id: 'cov-1',
      title: 'Стандартное',
      value: 'Стандартное',
    },
    {
      id: 'cov-2',
      title: 'Антикошка «Pet Screen» серое',
      value: 'Антикошка «Pet Screen» серое',
    },
    {
      id: 'cov-3',
      title: 'Антикошка «Pet Screen» черное',
      value: 'Антикошка «Pet Screen» черное',
    },
    {
      id: 'cov-4',
      title: 'Антикошка «Pet Screen» Silver Black',
      value: 'Антикошка «Pet Screen» Silver Black',
    },
    {
      id: 'cov-5',
      title: 'Антикошка «Pet Screen» бежевое',
      value: 'Антикошка «Pet Screen» бежевое',
    },
    {
      id: 'cov-6',
      title: 'Антикошка «Pet Screen» белое светоотражающее',
      value: 'Антикошка «Pet Screen» белое светоотражающее',
    },
    {
      id: 'cov-7',
      title: 'Антипыль / Антимошка',
      value: 'Антипыль / Антимошка',
    },
    {
      id: 'cov-8',
      title: 'Антипыльца «PollTex» черное',
      value: 'Антипыльца «PollTex» черное',
    },
    {
      id: 'cov-9',
      title: 'Максимальный обзор (Maxi-Vision)',
      value: 'Максимальный обзор (Maxi-Vision)',
    },
    {
      id: 'cov-10',
      title: 'Максимальный обзор (Transpatec)',
      value: 'Максимальный обзор (Transpatec)',
    },
    {
      id: 'cov-11',
      title: 'Антиптица (стальное)',
      value: 'Антиптица (стальное)',
    },
  ];
  const hinges = [
    {
      id: 'hing-1',
      title: 'С доводчиком',
      value: 'С доводчиком',
    },
    {
      id: 'hing-2',
      title: 'Без доводчика',
      value: 'Без доводчика',
    },
  ];
  const openMech = [
    {
      id: 'mech-1',
      title: 'Одностроннее',
      value: 'Одностроннее',
    },
    {
      id: 'mech-2',
      title: 'Встречное',
      value: 'Встречное',
    },
    {
      id: 'mech-3',
      title: 'Реверсивное',
      value: 'Реверсивное',
    },
    {
      id: 'mech-4',
      title: 'Вертикальное',
      value: 'Вертикальное',
    },
  ];
  const colors = [
    { key: 'color-1', name: 'Белый', color: '#ffffff' },
    { key: 'color-2', name: 'Коричневый', color: '#80432A' },
  ];
  const bracing = [
    {
      key: 'bracing-1',
      img: 'setup-z.jpg',
      value: 'Z-крепления',
    },
    {
      key: 'bracing-2',
      img: 'setup-inside.jpg',
      value: 'Внутренние зацепы',
    },
    {
      key: 'bracing-3',
      img: 'setup-shtift.jpg',
      value: 'Штифты',
    },
  ];
  const [errors, setErrors] = useState({
    width: false,
    height: false,
    right_height: false,
  });
  const [config, setConfig] = useState({
    width: 2000,
    height: 1500,
    right_height: 1200,
    type: types[0],
    coverage: coverage[0],
    hinges: hinges[0],
    openMech: openMech[0],
    borderColor: { name: 'Белый', color: '#ffffff', ral: false },
    units: 1,
    bracing: bracing[0],
    rail_width: 700,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [outPut, setOutPut] = useState([]);
  useEffect(() => {
    const array = [];
    array.push(`Количество: ${config.units}`);
    array.push(`Тип москитной сетки: ${config.type.title}`);
    array.push(`Ширина сетки: ${config.width} мм`);
    array.push(`Высота сетки: ${config.height} мм`);
    config.type.id === 'st-7' && array.push(`Высота сетки справа: ${config.right_height} мм`);
    if (config.type.id === 'st-6') {
      array.push(`Тип открывания: ${config.openMech.title}`);
    } else {
      array.push(`Тип полотна: ${config.coverage.title}`);
    }

    array.push(`Цвет рамки: ${config.borderColor.name}`);
    config.type.id === 'st-3' && array.push(`Петли: ${config.hinges.title}`);
    config.type.id === 'st-8' && array.push(`Длинна рельсы: ${config.rail_width} мм`);
    if (config.type.id === 'st-1' || config.type.id === 'st-7') {
      array.push(`Варианты установки: ${config.bracing.value}`);
    }
    setOutPut(array);
  }, [config]);

  const changeWidth = (e) => {
    const value = Number(onlyNumbers(e.target.value));
    if (value < config.type.minWidth || value > config.type.maxWidth) {
      setErrors((prev) => ({
        ...prev,
        width: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        width: false,
      }));
    }
    setConfig((prev) => ({
      ...prev,
      width: value,
    }));
  };
  const changeRightHeight = (e) => {
    const value = onlyNumbers(e.target.value);
    if (value < config.type.minHeight || value > config.type.maxHeight) {
      setErrors((prev) => ({
        ...prev,
        right_height: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        right_height: false,
      }));
    }
    setConfig((prev) => ({
      ...prev,
      right_height: value,
    }));
  };
  const changeHeight = (e) => {
    const value = onlyNumbers(e.target.value);
    if (value < config.type.minHeight || value > config.type.maxHeight) {
      setErrors((prev) => ({
        ...prev,
        height: true,
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        height: false,
      }));
    }
    setConfig((prev) => ({
      ...prev,
      height: value,
    }));
  };
  const changeType = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      width: value.maxWidth,
      height: value.maxHeight,
      type: value,
    }));
  }, []);

  const changeCoverage = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      coverage: value,
    }));
  }, []);

  const changeOpenMech = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      openMech: value,
    }));
  }, []);

  const changeHinges = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      hinges: value,
    }));
  }, []);

  const changeColor = useCallback((value, name) => {
    setConfig((prev) => ({
      ...prev,
      borderColor: {
        name: name,
        color: value,
        ral: false,
      },
    }));
  }, []);
  const changeRalColor = useCallback((name, color) => {
    setConfig((prev) => ({
      ...prev,
      borderColor: {
        name: name,
        color: color,
        ral: true,
      },
    }));
  }, []);
  const ralPickModalOpen = useCallback((e) => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  }, []);

  const ralPickModalClose = useCallback(() => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
  }, []);
  const increaseUnits = () => {
    setConfig((prev) => ({
      ...prev,
      units: prev.units + 1,
    }));
  };
  const decreaseUnits = () => {
    const total = config.units - 1 <= 0 ? 1 : config.units - 1;
    setConfig((prev) => ({
      ...prev,
      units: total,
    }));
  };
  const changeBracing = useCallback((value) => {
    setConfig((prev) => ({
      ...prev,
      bracing: value,
    }));
  }, []);
  const changeRailWidth = (e) => {
    const value = Number(onlyNumbers(e.target.value));
    setConfig((prev) => ({
      ...prev,
      rail_width: value,
    }));
  };
  const checkOutBtnClick = () => {
    const str = outPut.join(';\n');
    const modal = document.getElementById('calc-nets-order');
    const textArea = modal.querySelector('.js-calc-nets-output');
    textArea.value = str;
    modal.classList.add('_active');
    document.body.style.overflow = 'hidden';
  };
  return (
    <div className="calc-nets__body">
      <div className="calc-nets__grid">
        <div className="calc-nets__preview">
          <div className="calc-nets__visual-sizes">
            {config.type.id === 'st-7' && (
              <input
                value={config.right_height}
                type="text"
                className="calc-nets__sizer calc-nets__sizer_type_right"
                onChange={changeRightHeight}
              />
            )}
            <input
              value={config.width}
              type="text"
              className={`calc-nets__sizer ${
                config.type.class ? config.type.class : 'calc-nets__sizer_type_width'
              }`}
              onChange={changeWidth}
            />
            <input
              value={config.height}
              type="text"
              className="calc-nets__sizer calc-nets__sizer_type_height"
              onChange={changeHeight}
            />
            <ViewNets id={config.type.id} color={config.borderColor.color} />
            {/* {config.type.img} */}
            {/* <img className="_fw" src={`${path}/${config.type.img}`} alt="" /> */}
          </div>
          <div className="calc-nets__count">
            <div className="units">
              <div className="units__info">Количество:</div>
              <div className="units__group">
                <div className="units__btn" onClick={decreaseUnits}>
                  <svg
                    viewBox="0 0 16 3"
                    className="units__icon units__icon_minus"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 1.5C0 0.671573 0.671573 0 1.5 0H14.5C15.3284 0 16 0.671573 16 1.5C16 2.32843 15.3284 3 14.5 3H1.5C0.671573 3 0 2.32843 0 1.5Z"
                    />
                  </svg>
                </div>
                <input disabled type="text" className="units__input " value={config.units} />
                <div className="units__btn" onClick={increaseUnits}>
                  <svg
                    viewBox="0 0 17 17"
                    className="units__icon units__icon_plus"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 8.5C0 7.67157 0.671573 7 1.5 7H15.5C16.3284 7 17 7.67157 17 8.5C17 9.32843 16.3284 10 15.5 10H1.5C0.671573 10 0 9.32843 0 8.5Z"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.5 17C7.67157 17 7 16.3284 7 15.5L7 1.5C7 0.671574 7.67157 2.76286e-08 8.5 6.17101e-08C9.32843 9.57917e-08 10 0.671574 10 1.5L10 15.5C10 16.3284 9.32843 17 8.5 17Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="calc-nets__main">
          <div className="calc-nets__box">
            <Select
              label="Тип москитной сетки:"
              value={config.type}
              onChange={changeType}
              options={types}
            />
          </div>
          <div className="calc-nets__box">
            <div className="calc-nets__info">
              Укажите ширину от {config.type.minWidth} до {config.type.maxWidth} мм и высоту от{' '}
              {config.type.minHeight} до {config.type.maxHeight} мм.
            </div>
          </div>
          <div className="calc-nets__box">
            <div className="calc-nets__group">
              <div className="calc-nets__col calc-nets__col_w50">
                <label className="calc-nets__label">Ширина сетки</label>
                <div className="calc-nets__field">
                  <input
                    type="text"
                    value={config.width}
                    onChange={changeWidth}
                    className=" input input_bg_white"
                  />
                  <div className="calc-nets__helper">мм</div>
                </div>
                {errors.width && (
                  <div className="calc-nets__error">
                    Введите ширину в пределах от {config.type.minWidth} до {config.type.maxWidth}
                  </div>
                )}
              </div>
              <div className="calc-nets__col calc-nets__col_w50">
                <label htmlFor="" className="calc-nets__label">
                  Высота сетки
                </label>
                <div className="calc-nets__field">
                  <input
                    value={config.height}
                    type="text"
                    className=" input input_bg_white"
                    onChange={changeHeight}
                  />
                  <div className="calc-nets__helper">мм</div>
                </div>
                {errors.height && (
                  <div className="calc-nets__error">
                    Введите высоту в пределах от {config.type.minHeight} до {config.type.maxHeight}
                  </div>
                )}
              </div>
              {config.type.id === 'st-7' && (
                <div className="calc-nets__col calc-nets__col_w50">
                  <label className="calc-nets__label">Высота сетки справа</label>
                  <div className="calc-nets__field">
                    <input
                      value={config.right_height}
                      type="text"
                      className=" input input_bg_white"
                      onChange={changeRightHeight}
                    />
                    <div className="calc-nets__helper">мм</div>
                  </div>
                  {errors.right_height && (
                    <div className="calc-nets__error">
                      Введите высоту в пределах от {config.type.minHeight} до{' '}
                      {config.type.maxHeight}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {config.type.id === 'st-6' ? (
            <div className="calc-nets__box">
              <Select
                label="Тип открывания:"
                value={config.openMech}
                onChange={changeOpenMech}
                options={openMech}
              />
            </div>
          ) : (
            <div className="calc-nets__box">
              <Select
                label="Тип полотна:"
                value={config.coverage}
                onChange={changeCoverage}
                options={coverage}
              />
            </div>
          )}
          {config.type.id === 'st-3' && (
            <div className="calc-nets__box">
              <Select
                label="Петли:"
                value={config.hinges}
                onChange={changeHinges}
                options={hinges}
              />
            </div>
          )}
          <div className="calc-nets__box">
            <label className="calc-nets__label">Цвет рамки:</label>
            <div className="calc-nets__group">
              {colors.map((item) => (
                <div className="calc-nets__switch" key={item.key}>
                  <Switch
                    value={item.color}
                    name={item.name}
                    checked={
                      config.borderColor.name.toLowerCase() === item.name.toLowerCase() &&
                      !config.borderColor.ral
                    }
                    onChange={changeColor}
                  />
                </div>
              ))}
              <div className="calc-nets__switch">
                <Switch
                  value={
                    config.borderColor.ral
                      ? config.borderColor.color
                      : 'linear-gradient(91.51deg, #FF0000 2.41%, #FFA800 14.72%, #FFE600 32.63%, #ADFF00 49.26%, #00FFC2 61.18%, #0075FF 78.87%, #7000FF 95.81%)'
                  }
                  name={config.borderColor.ral ? 'Изменить цвет' : 'Покраска по RAL'}
                  onClick={ralPickModalOpen}
                  checked={config.borderColor.ral}
                />
                <RalModal
                  isOpen={modalOpen}
                  choosedColor={config.borderColor.name}
                  onClose={ralPickModalClose}
                  onClick={changeRalColor}
                />
              </div>
              {config.borderColor.ral && (
                <div className="calc-nets__informer">
                  <label className="calc-nets__label">Цвет по RAL:</label>
                  <input
                    type="text"
                    disabled
                    className="input input_bg_white"
                    value={config.borderColor.name}
                  />
                </div>
              )}
            </div>
          </div>
          {config.type.id === 'st-1' || config.type.id === 'st-7' ? (
            <div className="calc-nets__box">
              <label className="calc-nets__label">Варианты установки:</label>
              <div className="calc-nets__group">
                {bracing.map((item) => (
                  <div className="calc-nets__switch" key={item.key}>
                    <RadioImg
                      value={item.value}
                      img={item.img}
                      checked={config.bracing.value.toLowerCase() === item.value.toLowerCase()}
                      onClick={() => {
                        changeBracing(item);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
          {config.type.id === 'st-8' && (
            <div className="calc-nets__box">
              <label className="calc-nets__label">Длинна рельсы</label>
              <div className="calc-nets__field">
                <input
                  value={config.rail_width}
                  type="text"
                  className=" input input_bg_white"
                  onChange={changeRailWidth}
                />
                <div className="calc-nets__helper">мм</div>
              </div>
              {errors.right_height && (
                <div className="calc-nets__error">
                  Введите ширину в пределах от {config.type.minWidth} до {config.type.maxWidth}
                </div>
              )}
            </div>
          )}
          <div className="calc-nets__plate">
            <div className="calc-nets__output">Ваша конфигурация:</div>
            <ul className="calc-nets__list">
              {outPut.map((item, index) => (
                <li key={`output${index}`}>{item}</li>
              ))}
            </ul>
            <div className="calc-nets__callback">
              <button
                disabled={Object.values(errors).includes(true)}
                onClick={checkOutBtnClick}
                className="calc-nets__btn btn-base btn-base_secondary btn-base_md"
              >
                Вызвать замерщика
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
