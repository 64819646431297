import Swiper, { Navigation } from 'swiper';

export function fnShowCaseSlider() {
  try {
    const blocks = document.querySelectorAll('.js-fn-showcase-gallery');
    for (const item of blocks) {
      const prevEl = item.querySelector('.js-prevEl');
      const nextEl = item.querySelector('.js-nextEl');
      new Swiper(item.querySelector('.swiper'), {
        modules: [Navigation],
        // slidesPerView: 7,
        // spaceBetween: 30,
        navigation: {
          prevEl,
          nextEl,
          lockClass: 'lock',
          disabledClass: 'disabled',
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          400: {
            slidesPerView: 3,
            spaceBetween: 15,
          },
          600: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
          900: {
            slidesPerView: 6,
            spaceBetween: 30,
          },
          1100: {
            slidesPerView: 7,
            spaceBetween: 30,
          },
        },
      });
    }
  } catch (error) {}
}
