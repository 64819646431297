import { CreateMobileSlider } from '@/js/helpers';
import { Pagination } from 'swiper';
export class FtServ {
  constructor() {
    this.init();
  }
  init = () => {
    new CreateMobileSlider({
      containerCssSelector: '.js-ft-serv-slider',
      slideCssSelector: '.js-ft-serv-slide',
      mobileBreakPoint: '1200',
      sliderOptions: {
        spaceBetween: 10,
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          550: {
            slidesPerView: 2,
          },
          780: {
            slidesPerView: 3,
          },
        },
      },
    });
  };
}
