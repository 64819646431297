import Swiper, { Navigation, Pagination } from 'swiper';

export class PersonalSlider {
  constructor() {
    this.blocks = document.querySelectorAll('.js-personal-slider');
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.blocks) {
      const sliderEl = item.querySelector('.swiper');
      const prevEl = item.querySelector('.personal__control_prev');
      const nextEl = item.querySelector('.personal__control_next');
      new Swiper(sliderEl, {
        modules: [Navigation, Pagination],
        spaceBetween: 30,
        navigation: {
          prevEl: prevEl,
          nextEl: nextEl,
          disabledClass: 'disabled',
        },
        pagination: {
          el: '.swiper-pagination',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          575: {
            slidesPerView: 2,
          },
          850: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      });
    }
  };
}
