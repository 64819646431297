import Swiper, { Navigation, Thumbs } from 'swiper';

export class CertSlider {
  constructor() {
    this.block = document.querySelector('.cert');
    this.block && this.init();
  }
  init = () => {
    const childEl = this.block.querySelector('.js-slider-child');
    const mainEl = this.block.querySelector('.js-slider-main');
    const nextEl = this.block.querySelector('.cert__control_next');
    const prevEl = this.block.querySelector('.cert__control_prev');
    const childSlider = new Swiper(childEl, {
      modules: [Thumbs],
      breakpoints: {
        700: {
          slidesPerView: 3,
          spaceBetween: 15,
        },
        900: {
          slidesPerView: 4,
          spaceBetween: 15,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 25,
        },
      },
    });
    new Swiper(mainEl, {
      modules: [Thumbs, Navigation],
      navigation: {
        nextEl,
        prevEl,
      },
      thumbs: {
        swiper: childSlider,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        400: {
          slidesPerView: 2.5,
        },
        600: {
          slidesPerView: 3,
        },

        801: {
          slidesPerView: 1,
        },
      },
    });
  };
}
