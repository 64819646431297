// Test import of a JavaScript module
import { Drawer } from '@/blocks/drawer';
import { NavBar } from '@/blocks/navbar';
import { Header } from '@/blocks/header';
import { FScreen } from '@/blocks/fscreen';
import { Parthners } from '@/blocks/parthners';
import { ShowCase } from '@/blocks/showcase';
import { ProfCards } from '@/blocks/prof';
import { FtServ } from '@/blocks/ft-serv';
// import { CardList } from '@/blocks/card-list';
import { Usage } from '@/blocks/usage';
import { DocsSlider } from '@/blocks/docs';
import { AntSlider } from '@/blocks/answer';
import { VideoReviews } from '@/blocks/reviews';
import { PersonalSlider } from '@/blocks/personal';
import { GalleryBlock } from '@/blocks/gallery';
import { TypesSlider } from '@/blocks/types';
import { Contacts } from '@/blocks/contact';
import { PriceBlock } from '@/blocks/price';
import { Steps } from '@/blocks/steps';
import { FtTypes } from '@/blocks/ft-types';
import { CalcSize } from '@/blocks/calc-size';
import { runProjectCalc } from '@/blocks/project-calc';
import { FormValidation } from '@/js/formValidation';
import { CreateMobileSlider, inputFilePlaceholder } from '@/js/helpers';
import { Modal } from '@/blocks/modal/modal';
import { FInTypes } from '@/blocks/fin-types';
import { DeadLInes } from '@/blocks/deadlines';
import { PriceTable } from '@/blocks/price-table';
import { RepairForm } from '@/blocks/repair-call';
import { Prizes } from '@/blocks/prizes';
import { Brands } from '@/blocks/brands';
import { Akcii } from '@/blocks/akcii';
import { InfoCard } from '@/blocks/info-card';
import { NewsSlider } from '@/blocks/news';
import { CertSlider } from '@/blocks/cert';
import { HandleCalc } from '@/blocks/handle-calc';
import { SmartMobileSlider } from '@/js/mobileSliders';
import initCalcNets from '@/blocks/calc-nets';
import initCalcPrice from '@/blocks/calc-price';
import { PlasticWSlider } from '@/blocks/plastic-w';
import { PdCharsSlider } from '@/blocks/pd-chars';
import { prodSlider } from '@/blocks/prod-tabs';
import { GlCalc } from '@/blocks/gl-calc';
import { fnShowCaseSlider } from '@/blocks/fn-showcase';
import { citylist } from '@/blocks/city-list';
import { profilSlider } from '@/blocks/profil';
import { kluchSlider } from '@/blocks/kluch';
import { cenySlider } from '@/blocks/ceny-tip';
import { workSlider } from '@/blocks/work';
import { otkrSlider } from '@/blocks/otkr';
import { blogSlider } from '@/blocks/blog';
import { shemaSlider } from '@/blocks/shema';

import { Pagination } from 'swiper';
import lozad from 'lozad';
import 'fslightbox';
import 'core-js/es/promise/all-settled';
import Accordion from 'accordion-js';
// Test import of styles
import '@/styles/index.scss';

// import libs
import 'swiper/scss';
import 'simplebar/dist/simplebar.css';
import 'intersection-observer';
import ResizeObserver from 'resize-observer-polyfill';
import { ClassicTabs } from './js/tabs';
window.ResizeObserver = ResizeObserver;

// Appending to the DOM
class Root {
  constructor() {
    this.init();
  }
  init = () => {
    document.addEventListener('DOMContentLoaded', () => {
      this.stageOne();
      this.stageTwo();
    });
    // if (document.querySelector('#calc-nets')) {
    //   import(/* webpackChunkName: "calcNets" */ '@/blocks/calc-nets').then((item) =>
    //     item.default()
    //   );
    // }
    initCalcNets();
  };
  stageOne = () => {
    this.classicTabsInit();
    new NavBar();
    new Drawer();
    new Header();
    new FScreen();
    new CreateMobileSlider({
      containerCssSelector: '.js-awards-slider',
      slideCssSelector: '.js-awards-slider-slide',
      mobileBreakPoint: '1200',
      sliderOptions: {
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          450: {
            slidesPerView: 2,
          },
          740: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 4,
          },
        },
      },
    });
    new CreateMobileSlider({
      containerCssSelector: '.js-ver-slider',
      slideCssSelector: '.js-ver-slider-slide',
      mobileBreakPoint: '1200',
      sliderOptions: {
        modules: [Pagination],
        pagination: {
          el: '.swiper-pagination',
        },
        spaceBetween: 20,
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
          }
        },
      },
    });
    new Parthners();
    new ShowCase();
    new ProfCards();
    window.formsControl = new FormValidation();
    window.formsControl.initAll();
    new FtServ();
    // new CardList();
    new Usage();
    new DocsSlider();
    new AntSlider();
    new VideoReviews();
    new PersonalSlider();
    new GalleryBlock();
    new Contacts();
    new profilSlider();
    new kluchSlider();
    new cenySlider();
    new workSlider();
    new otkrSlider();
    new blogSlider();
    new shemaSlider();

    inputFilePlaceholder();
    new TypesSlider();
    new PriceBlock();
    new Steps();
    new FtTypes();
    window.calcSize = new CalcSize();
    runProjectCalc();
    new FInTypes();
    new DeadLInes();
    new PriceTable();
    window.repairForm = new RepairForm();
    new Prizes();
    new Brands();
    new Akcii();
    new SmartMobileSlider();
    new InfoCard();
    new NewsSlider();
    new CertSlider();
    new HandleCalc();
    initCalcPrice();
    new PlasticWSlider();
    new PdCharsSlider();
    prodSlider();
    new GlCalc();
    fnShowCaseSlider();
    citylist();
    quiz();
  };
  stageTwo = () => {
    const el = document.querySelectorAll('.js-lazy');
    const observer = lozad(el, {
      rootMargin: '10% 50%',
    });
    observer.observe();
    window.modal = new Modal();

    // refreshFsLightbox();
    //initi accordion
    const accordionEl = document.querySelectorAll('.js-accordion');
    for (const item of accordionEl) {
      new Accordion(item, {
        elementClass: 'js-acc-el',
        triggerClass: 'js-acc-trigger',
        panelClass: 'js-acc-panel',
        activeClass: 'active',
      });
    }
  };
  classicTabsInit = () => {
    const items = document.querySelectorAll('.js-classic-tabs');
    for (const item of items) {
      new ClassicTabs({
        blockSelector: item,
      });
    }
  };
}
new Root();

function quiz() {
  if(document.getElementById("quiz")) {
    let curr_step = 0;

    const listSteps = document.querySelectorAll(".js-step");
    const nextBtn0 = document.getElementById("next-btn_0");
    const nextBtn1 = document.getElementById("next-btn_1");
    const nextBtn2 = document.getElementById("next-btn_2");
    const prevBtns = document.getElementsByClassName("js-prev-btn");
    // const submitBtn = document.getElementById("submit-btn");

    nextBtn0.onclick = function () {
      to_step(curr_step + 1);
    };
    nextBtn1.onclick = function () {
      to_step(curr_step + 1);
    };
    nextBtn2.onclick = function () {
      to_step(curr_step + 1);
    };

    for (var i = 0; i < prevBtns.length - 1; i++) {
      prevBtns[i].onclick = function () {
        to_step(curr_step - 1);
      };
    }

    function to_step(index) {
      curr_step = index;
      for (var i = 0; i < listSteps.length; i++) {
        if (listSteps[i].classList.contains("quiz__question--active")) {
          listSteps[i].classList.remove("quiz__question--active");
        }
      }
      listSteps[index].classList.add("quiz__question--active");
    }

    // submitBtn.onclick = function (evt) {
    //   evt.preventDefault();
    //
    //   const form = document.getElementById("form-data");
    //   let data = {};
    //   for (let i = 0; i < form.elements.length; i++) {
    //     data[form.elements[i].name] = form.elements[i].value;
    //   }
    //
    //   // let xmlhttp = getXmlHttp();
    //   // xmlhttp.open('POST', 'ajax/', true);
    //   // xmlhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    //   // xmlhttp.send(data);
    //   // xmlhttp.onreadystatechange = function() {
    //   //   if (xmlhttp.readyState == 4) {
    //   //     if(xmlhttp.status == 200) {
    //   // popup.classList.add("popup-success--open");
    //   to_step(curr_step + 1);
    //
    //   //     }
    //   //   }
    //   // };
    // };

    function getXmlHttp() {
      let xmlhttp;
      try {
        xmlhttp = new ActiveXObject("Msxml2.XMLHTTP");
      } catch (e) {
        try {
          xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
        } catch (E) {
          xmlhttp = false;
        }
      }
      if (!xmlhttp && typeof XMLHttpRequest != "undefined") {
        xmlhttp = new XMLHttpRequest();
      }
      return xmlhttp;
    }

    // popupClose.onclick = function () {
    //   popup.classList.remove("popup-success--open");
    // };
  }
}