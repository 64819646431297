import React, { useState, useRef, useEffect, memo } from 'react';

const Select = memo(function Select(props) {
  const { options, label, onChange, value } = props;

  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);
  function handleClickOutside(event) {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpen(false);
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef]);

  const clickHandler = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="calc-select" ref={selectRef}>
      <div className="calc-select__title">{label}</div>
      <div className={`calc-select__box ${open && 'active'}`} onClick={clickHandler}>
        <div className="calc-select__placeholder">{value.title}</div>
        <div className="calc-select__shape">
          <svg
            className="calc-select__arrow"
            viewBox="0 0 24 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.6066 4.51472L13.0606 14.0607C12.4748 14.6464 11.5251 14.6464 10.9393 14.0607L1.39335 4.51472C0.807568 3.92893 0.807568 2.97919 1.39335 2.3934C1.97914 1.80761 2.92889 1.80761 3.51468 2.3934L10.5 9.37868L10.5 5.484e-07L13.5 4.17266e-07L13.5 9.37868L20.4852 2.3934C21.071 1.80761 22.0208 1.80761 22.6066 2.3934C23.1923 2.97918 23.1923 3.92893 22.6066 4.51472Z"
            />
          </svg>
        </div>
        <div className="calc-select__dropdown">
          {options.map((item) => (
            <div
              key={item.id}
              className={`calc-select__option ${item.id === value.id && 'active'} `}
              onClick={() => {
                onChange(item);
              }}
            >
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
export default Select;
