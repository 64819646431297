import Accordion from 'accordion-js';

export class InfoCard {
  constructor() {
    this.block = document.querySelector('.js-infocard-ac');
    this.block && this.init();
  }
  init = () => {
    new Accordion('.js-infocard-ac', {
      triggerClass: 'js-card-toggler',
      panelClass: 'ac-card__descr',
      elementClass: 'ac-card',
      showMultiple: true,
      onlyChildNodes: false,
    });
  };
}
