export const profileData = {
  rehau: [
    { id: 'r-1', title: 'Rehau Blitz', value: 'Rehau Blitz' },
    { id: 'r-2', title: 'Rehau CONSTANTA', value: 'Rehau CONSTANTA' },
    { id: 'r-3', title: 'Rehau GRAZIO', value: 'Rehau GRAZIO' },
    { id: 'r-4', title: 'Rehau DELIGHT', value: 'Rehau DELIGHT' },
    { id: 'r-5', title: 'Rehau BRILLANT-DESIGN', value: 'Rehau BRILLANT-DESIGN' },
    { id: 'r-6', title: 'Rehau INTELIO', value: 'Rehau INTELIO' },
    { id: 'r-7', title: 'Rehau GENEO', value: 'Rehau GENEO' },
  ],
  melke: [
    {
      id: 'r-1',
      title: 'Melke Lite 60',
      value: 'Melke Lite 60',
    },
    {
      id: 'r-2',
      title: 'Melke Lite 70',
      value: 'Melke Lite 70',
    },
    {
      id: 'r-3',
      title: 'Melke Smart 60',
      value: 'Melke Smart 60',
    },
    {
      id: 'r-4',
      title: 'Melke Evolution 70',
      value: 'Melke Evolution 70',
    },
    {
      id: 'r-5',
      title: 'Melke Art 70',
      value: 'Melke Art 70',
    },
    {
      id: 'r-6',
      title: 'Melke Centum',
      value: 'Melke Centum',
    },
  ],
};
