import Swiper, { Navigation } from 'swiper';

export class FtTypes {
  constructor() {
    this.block = document.querySelector('.js-ft-types-slider');
    this.block && this.init();
  }
  init = () => {
    const slider = this.block.querySelector('.swiper');
    const prev = this.block.querySelector('.ft-types__btn_prev');
    const next = this.block.querySelector('.ft-types__btn_next');
    new Swiper(slider, {
      modules: [Navigation],
      slidesPerView: 6,
      spaceBetween: 30,
      loop: true,
      navigation: {
        prevEl: prev,
        nextEl: next,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        400: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
        1601: {
          slidesPerView: 6,
        },
      },
    });
  };
}
