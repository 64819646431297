import Swiper, { Navigation, Pagination } from 'swiper';

export class Prizes {
  constructor() {
    this.block = document.querySelector('.js-prizes-slider');
    this.block && this.init();
  }
  init = () => {
    const slider = this.block.querySelector('.swiper');
    const prev = this.block.querySelector('.st-slider__control_prev');
    const next = this.block.querySelector('.st-slider__control_next');
    const pagination = this.block.querySelector('.st-slider__pagination');
    new Swiper(slider, {
      slidesPerView: 4,
      spaceBetween: 30,
      modules: [Navigation, Pagination],
      navigation: {
        prevEl: prev,
        nextEl: next,
        disabledClass: 'disabled',
      },
      pagination: {
        el: pagination,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        575: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
        850: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
    });
  };
}
