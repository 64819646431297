import Swiper, { Navigation, Pagination } from 'swiper';
import debounce from 'lodash-es/debounce';

export class CreateMobileSlider {
  constructor(options) {
    const defaultOptions = {
      containerCssSelector: '._js-mobile-slider',
      slideCssSelector: '._js-mobile-slide',
      paginationClass: 'swiper-pagination',
      //ширина при которой появится слайдер
      mobileBreakPoint: '1200',
      sliderOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        },
      },
    };
    //создаем объект настроект с учетом переданных опций
    this.options = { ...defaultOptions, ...options };
    this.gridEl =
      typeof this.options.containerCssSelector === 'string'
        ? document.querySelector(this.options.containerCssSelector)
        : this.options.containerCssSelector;
    this.createdSliderEl = null;
    this.gridEl && this._init();
  }
  _init = () => {
    this._renderSliderContainer();
    this._toggleVisibilityOfSliderAndGrid();
    window.addEventListener(
      'resize',
      debounce(() => {
        this._toggleVisibilityOfSliderAndGrid();
      }, 500)
    );
  };
  _insertSlides = (containerNode, childItem, keyForsearch) => {
    const slideEl = document.createElement('div');
    slideEl.classList.add('swiper-slide');
    //Клонирование со всеми дочерними эл.
    const clone = childItem.cloneNode(true);
    //фикс для корректной работы с fsgallery
    if (clone.tagName === 'A') {
      clone.setAttribute('data-fslightbox', keyForsearch);
    }
    slideEl.appendChild(clone);
    containerNode.appendChild(slideEl);
  };
  _createRandomString = (length) => {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  _renderSliderContainer = () => {
    const keyForsearch = this._createRandomString(5);
    const slidesEl =
      typeof this.options.slideCssSelector === 'string'
        ? this.gridEl.querySelectorAll(this.options.slideCssSelector)
        : this.options.slideCssSelector;
    this.gridEl.insertAdjacentHTML(
      'beforebegin',
      `
				  <div class="swiper" id="${keyForsearch}">
					  <div class="swiper-wrapper"></div>
					  <div class="${this.options.paginationClass}"></div>
				  </div>
			  `
    );
    this.createdSliderEl = document.getElementById(keyForsearch);
    const sliderContainerNode = this.createdSliderEl.querySelector('.swiper-wrapper');
    Array.prototype.forEach.call(slidesEl, (item) => {
      this._insertSlides(sliderContainerNode, item);
    });
    //Инциализация слайдера
    new Swiper(this.createdSliderEl, this.options.sliderOptions, keyForsearch);
  };
  _toggleVisibilityOfSliderAndGrid = () => {
    if (window.matchMedia(`(max-width: ${this.options.mobileBreakPoint}px)`).matches) {
      this.gridEl.style.cssText = 'display:none';
      this.createdSliderEl.style.cssText = 'display:block';
    } else {
      this.gridEl.style.cssText = '';
      this.createdSliderEl.style.cssText = 'display:none';
    }
  };
}
export class SliderWithCustomDots {
  constructor(opt) {
    const defaultOptions = {
      sliderEl: null, // single element
      dots: null, // collection of element
      prevEl: null, // single element
      nextEl: null, // single element
      parentDots: null,
      sliderSpeed: 600,
      slideChangeCallback: null,
      breakpoints: {
        320: {
          slidesPerView: 1.1,
          spaceBetween: 20,
        },
        1201: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
    this.options = { ...defaultOptions, ...opt };
    this.sliderEl = this.options.sliderEl;
    this.dotsEl = this.options.dots;
    this.parentDots = this.options.parentDots;
    this.prevActiveKey = null;
    this.hashData = {};
    if (this.sliderEl && this.dotsEl) {
      this.init();
    }
  }
  init = () => {
    const self = this;
    self.createHashData();

    new Swiper(self.sliderEl, {
      modules: [Navigation],
      speed: self.options.sliderSpeed,
      rewind: true,
      navigation: {
        prevEl: self.options.prevEl,
        nextEl: self.options.nextEl,
      },
      breakpoints: self.options.breakpoints,
      on: {
        init: (swiper) => {
          self.changeActiveBtn(swiper.activeIndex);
        },
        afterInit: (swiper) => {
          self.controlInit(swiper);
        },
        slideChange: (swiper) => {
          self.changeActiveBtn(swiper.activeIndex);
          self.options.slideChangeCallback &&
            self.options.slideChangeCallback(swiper.activeIndex, swiper);
        },
      },
    });
  };
  createHashData = () => {
    for (const item of this.dotsEl) {
      const key = item.getAttribute('data-index');
      if (this.hashData[key]) {
        this.hashData[key].push(item);
      } else {
        this.hashData[key] = [item];
      }
    }
  };
  controlInit = (swiper) => {
    for (const item of this.dotsEl) {
      item.addEventListener('click', () => {
        swiper.slideTo(item.getAttribute('data-index'));
      });
    }
  };
  changeActiveBtn = (activeIndex) => {
    if (this.prevActiveKey !== null) {
      for (const item of this.hashData[this.prevActiveKey]) {
        item.classList.remove('active');
      }
    }
    for (const item of this.hashData[activeIndex]) {
      item.classList.add('active');
      if (this.parentDots) {
        const scroll = item.offsetLeft;
        this.parentDots.scroll({
          left: scroll,
          behavior: 'smooth',
        });
      }
    }
    this.prevActiveKey = activeIndex;
  };
}

export function inputFilePlaceholder() {
  const blocks = document.querySelectorAll('.js-input-file');
  if (blocks.length > 0) {
    for (const blockEl of blocks) {
      const placeholder = blockEl.getAttribute('data-placeholder');
      const input = blockEl.querySelector('.file-field__input');
      const helper = blockEl.querySelector('.file-field__helper');
      input.addEventListener('change', (e) => {
        e.target.files.length > 0
          ? (helper.innerHTML = e.target.files[0].name)
          : (helper.innerHTML = placeholder);
      });
      helper.innerHTML = placeholder;
    }
  }
}
export const onlyNumbers = (value) => {
  return Number(value.replace(/[^.\d]+/g, '').replace(/^([^\.]*\.)|\./g, '$1'));
};
