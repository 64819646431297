import Swiper, { Pagination } from 'swiper';

export class shemaSlider {
  constructor() {
    this.blocks = document.querySelectorAll('.js-slider-shema');
    this.blocks.length > 0 && this.init();
  }
  init = () => {
    for (const item of this.blocks) {
      const sliderEl = item.querySelector('.swiper');
      const slider = new Swiper(sliderEl, {
        modules: [Pagination],
        spaceBetween: 16,
        pagination: {
          el: '.swiper-pagination',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      });
    }
  };
}
