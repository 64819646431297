import { FormValidation } from '@/js/formValidation';
import { SelectStyled } from '@/js/select';
export class RepairForm extends FormValidation {
  constructor() {
    super();
    this.block = document.querySelector('.js-repair-form');
    this.block && this.init();
  }
  init = () => {
    const block = document.querySelector('.js-repair-form');
    this.initSelect();
    this.simpleCallBack(block);
  };
  initSelect = () => {
    const select = document.querySelectorAll('.js-select');
    for (const item of select) {
      new SelectStyled(item);
    }
  };
}
