import Swiper, { Navigation, Pagination } from 'swiper';

export class VideoReviews {
  constructor() {
    this.blocks = document.querySelectorAll('.js-video-reviews');
    this.blocks.length > 0 && this.init();
    this.blocks2 = document.querySelectorAll('.js-video-reviews-2');
    this.blocks2.length > 0 && this.init();
  }
  init = () => {
    if(this.blocks) {
      for (const item of this.blocks) {
        const sliderEl = item.querySelector('.swiper');
        const prevEl = item.querySelector('.reviews__control_prev');
        const nextEl = item.querySelector('.reviews__control_next');
        const slider = new Swiper(sliderEl, {
          modules: [Navigation, Pagination],
          spaceBetween: 30,
          navigation: {
            prevEl: prevEl,
            nextEl: nextEl,
            disabledClass: 'disabled',
          },
          pagination: {
            el: '.swiper-pagination',
          },
          breakpoints: {
            450: {
              slidesPerView: 2,
            },
            900: {
              slidesPerView: 3,
            },
          },
          // on: {
          //   breakpoint: (swiper, breakpoint) => {
          //     if (swiper.slides.length < breakpoint.slidesPerView) {
          //       swiper.wrapperEl.classList.add('swiper-wrapper-center');
          //     } else {
          //       swiper.wrapperEl.classList.remove('swiper-wrapper-center');
          //     }
          //   },
          // },
        });
      }
    }
    if(this.blocks2) {
      for (const item2 of this.blocks2) {
        const sliderEl2 = item2.querySelector('.swiper');
        const prevEl2 = item2.querySelector('.cert__control_prev');
        const nextEl2 = item2.querySelector('.cert__control_next');
        const slider2 = new Swiper(sliderEl2, {
          modules: [Navigation, Pagination],
          spaceBetween: 30,
          navigation: {
            prevEl: prevEl2,
            nextEl: nextEl2,
            disabledClass: 'disabled',
          },
          pagination: {
            el: '.swiper-pagination',
          },
          breakpoints: {
            450: {
              slidesPerView: 2,
            },
            900: {
              slidesPerView: 3,
            },
          },
          // on: {
          //   breakpoint: (swiper, breakpoint) => {
          //     if (swiper.slides.length < breakpoint.slidesPerView) {
          //       swiper.wrapperEl.classList.add('swiper-wrapper-center');
          //     } else {
          //       swiper.wrapperEl.classList.remove('swiper-wrapper-center');
          //     }
          //   },
          // },
        });
      }
    }
  };
}
