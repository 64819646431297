import { ClassicTabs } from '@/js/tabs';

export class Steps {
  constructor() {
    this.block = document.querySelector('.js-steps-tabs');
    this.block && this.init();
  }
  init = () => {
    new ClassicTabs({
      blockSelector: this.block,
      controlSelector: '.js-tab-control',
      viewSelector: '.js-tab-view',
      setupOpenFirstTab: true,
    });
  };
}
