import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';

export class DocsSlider {
  constructor() {
    this.block = document.querySelector('.js-docs-slider');
    this.block && this.init();
  }
  init = () => {
    const mainEl = this.block.querySelector('.js-docs-slider-main');
    const thumbEl = this.block.querySelector('.js-docs-slider-thumb');
    const nextEl = this.block.querySelector('.js-docs-slider-next');
    const thumbSlider = new Swiper(thumbEl, {
      modules: [Thumbs],
      spaceBetween: 20,

      grabCursor: true,
      watchSlidesProgress: true,
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 3,
        },
        993: {
          slidesPerView: 4,
        },
      },
    });
    const mainSlider = new Swiper(mainEl, {
      modules: [Thumbs, Navigation, Pagination],
      spaceBetween: 20,
      navigation: {
        nextEl: nextEl,
      },
      pagination: {
        el: '.swiper-pagination',
      },
      thumbs: {
        swiper: thumbSlider,
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 3,
        },
        576: {
          slidesPerView: 1,
        },
      },
    });
  };
}
