import Swiper, { Navigation, Pagination, Thumbs } from 'swiper';
export class workSlider {
  constructor() {
    this.block = document.querySelector('.js-work-slider');
    this.blocks = document.querySelectorAll('.primer__slider');
    this.block && this.init();
  }
  init = () => {
    const slider = this.block.querySelector('.swiper');
    const prev = this.block.querySelector('.work__control_prev');
    const next = this.block.querySelector('.work__control_next');
    const pagination = this.block.querySelector('.work__pagination');
    new Swiper(slider, {
      slidesPerView: 1,
      spaceBetween: 30,
      modules: [Navigation, Pagination],
      navigation: {
        prevEl: prev,
        nextEl: next,
        disabledClass: 'disabled',
      },
      pagination: {
        el: pagination,
      },
      breakpoints: {
        992: {
          slidesPerView: 2,
          spaceBetween: 30,
        }
      },
    });

    for (let i = 0; i < this.blocks.length; i++) {
      let swiperThumbs = new Swiper(document.querySelector(`.primer__slider-${i} .primer-thumbs`), {
        spaceBetween: 6,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
      });
      let swiperMain = new Swiper(document.querySelector(`.primer__slider-${i} .primer-main`), {
        modules: [Thumbs],
        spaceBetween: 10,
        thumbs: {
          swiper: swiperThumbs,
        },
      });
    }
  };
}
