import React, { memo } from 'react';

const Switch = memo(function Switch(props) {
  const { value, name, checked, onChange, ...rest } = props;
  const changeHandler = (e) => {
    onChange && onChange(e.target.value, name);
  };

  return (
    <div className="sv-switch" {...rest}>
      <label className="sv-switch__label">
        <input
          className="sv-switch__input"
          type="radio"
          checked={checked}
          onChange={changeHandler}
          value={value}
        />
        <span className="sv-switch__pallete" style={{ background: value }}>
          <span className="sv-switch__mark">
            <svg
              className="sv-switch__icon"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6327 0.283122C12.0836 0.695248 12.1251 1.40627 11.7255 1.87123L5.92381 8.62122C5.728 8.84904 5.45101 8.9853 5.1561 8.99888C4.86119 9.01246 4.57352 8.9022 4.3588 8.69329L0.342277 4.7854C-0.095956 4.35902 -0.116039 3.64701 0.29742 3.19508C0.71088 2.74316 1.40131 2.72245 1.83954 3.14882L5.03752 6.2603L10.0927 0.378791C10.4923 -0.0861714 11.1818 -0.129004 11.6327 0.283122Z"
                fill="black"
              />
            </svg>
          </span>
        </span>
        <span className="sv-switch__name">{name}</span>
      </label>
    </div>
  );
});
export default Switch;
