import Swiper, { Navigation, Pagination } from 'swiper';

export class NewsSlider {
  constructor() {
    this.block = document.querySelector('.news');
    this.block && this.init();
  }
  init = () => {
    const sliderEl = this.block.querySelector('.swiper');
    const prevEl = this.block.querySelector('.st-slider__control_prev');
    const nextEl = this.block.querySelector('.st-slider__control_next');
    new Swiper(sliderEl, {
      spaceBetween: 20,
      navigation: {
        prevEl,
        nextEl,
        lockClass: 'disabled',
        disabledClass: 'disabled',
      },
      pagination: {
        el: '.swiper-pagination',
      },
      modules: [Navigation, Pagination],
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        490: {
          slidesPerView: 1.5,
        },
        720: {
          slidesPerView: 2,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    });
  };
}
