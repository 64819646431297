import Swiper, { Autoplay, Navigation } from 'swiper';

export class FScreen {
  constructor() {
    this.block = document.querySelector('.js-fscreen-slider');
    this.next = null;
    this.prev = null;
    this.block && this.init();
  }
  init = () => {
    const slider = this.block.querySelector('.swiper');
    this.prev = this.block.querySelector('.fscreen__control_prev');
    this.next = this.block.querySelector('.fscreen__control_next');
    new Swiper(slider, {
      slidesPerView: 1,
      modules: [Navigation, Autoplay],
      autoplay: {
        delay: 5000,
      },
      loop: true,
      navigation: {
        prevEl: this.prev,
        nextEl: this.next,
        disabledClass: 'hide',
      },
    });
    if (this.next) {
      this.setupGroupCenter();
    }
  };
  setupGroupCenter = () => {
    const group = this.block.querySelectorAll('.fscreen__group');
    for (const item of group) {
      const mt = this.next.getBoundingClientRect().y - item.getBoundingClientRect().y - 15;
      if (mt > 0) {
        item.style.cssText = `margin-top:${mt}px`;
      }
    }
  };
}
